
  import Bot from 'models/bot.coffee'
  import ContextParameter from 'models/context_parameter.coffee'
  import ContextInterface from 'models/context_interface.coffee'
  import ContextParameterEditor from './context_parameter_editor.vue'
  import ContextInterfaceEditor from './context_interface_editor.vue'
  # import Contextbadge from './contextbadge'
  import { H1 } from 'components/generic'
  import { ViewGridIcon } from '@heroicons/vue/solid'

  export default
    props:
      bot: Bot
      parameterKey:
        type: String
        default: null

    data: ->
      contextParameter = new ContextParameter()

      contextInterface: null
      contextParameter: contextParameter
      editing: false

    computed:
      key: ->
        @parameterKey || @$route.params.parameterKey

    watch:
      key: -> @setParameters()
      '$root.contextParameters': -> @setParameters()

    created: ->
      @setParameters()

    methods:
      setParameters: ->
        if !@key?
          @editing = true
          return
        @contextParameter = @$root.contextParameters.find (parameter) => parameter.key == @key
        if @$route.params.parameterKey?
          window.breadcrumbs.append(text: @contextParameter.label)
        @contextInterface = await @bot.config.contextInterface(@contextParameter).load()

    components:
      ContextInterfaceEditor: ContextInterfaceEditor
      ContextParameterEditor: ContextParameterEditor
      H1: H1
      ViewGridIcon: ViewGridIcon
