
  import PayloadSlot from 'models/payload_slot.coffee'
  import DialogNode from 'models/dialog_node.coffee'
  import UniversalInput from 'components/botbuilder/inputs/universal_input.vue'
  import { Combobox } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: PayloadSlot
      targetNode: DialogNode
      availableSlots: Array

    emits: ['update:modelValue', 'remove']

    data: ->
      slot: @modelValue
      availableSlotsForCombobox: []

    computed:
      slotDefinition: ->
        @availableSlots.find (slotDefinition) => slotDefinition.slotRole == @slot.slotRole
      contextParameter: ->
        return if @slotDefinition?.type != 'AttributeSlotDefinition'
        @$root.contextParameterByKey?(@slotDefinition.parameterKey)
      valueType: ->
        return if !@slotDefinition?
        switch @slotDefinition.type
          when 'TypedSlotDefinition'
            @slotDefinition.valueType
          when 'AttributeSlotDefinition'
            @contextParameter?.valueType
      entitySchemaKey: ->
        @contextParameter?.entitySchemaKey

    watch:
      modelValue: ->
        @slot = @modelValue

    created: ->
      @setAvailableSlotsForCombobox()

    methods:
      setAvailableSlotsForCombobox: (query) ->
        @availableSlotsForCombobox = Array.unique(
          [query].concat(
            @availableSlots.map (slotDefinition) -> slotDefinition.slotRole
          )
        )
          .filter (str) -> str
          .sort()
      filterSlotRoles: (slotRole, query)->
        (slotRole || '').toLowerCase().startsWith(query.toLowerCase())
      update: ->
        @$emit('update:modelValue', @slot)

    components:
      Combobox: Combobox
      UniversalInput: UniversalInput
      XIcon: XIcon
