
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

  export default
    props:
      buttonClass:
        type: String
        default: ''
      hover:
        type: Boolean
        default: false

    methods:
      show: (open) ->
        return if !@hover
        return if open
        @$refs.popoverButton.$el.click()
      hide: (open) ->
        return if !@hover
        return if !open
        @$refs.popoverButton.$el.click()

    components:
      Popover: Popover
      PopoverButton: PopoverButton
      PopoverPanel: PopoverPanel
