
  import Label from './label.vue'

  export default
    props:
      modelValue: [String, Number]
      label: String
      placeholder: String
      helpText: String
      errorText: String
      valid:
        type: Boolean
        default: true
      disabled:
        type: Boolean
        default: false
      inputType:
        type: String
        default: 'text'
      min:
        type: String
        default: '0'
      maxLength:
        type: Number
        default: null
      counter:
        type: Boolean
        default: false
      notFirst:
        type: Boolean
        default: false
      notLast:
        type: Boolean
        default: false
      hGroupPosition:
        type: String
        default: 'none'

    emits: ['focus', 'blur', 'update:modelValue']

    data: ->
      value: @modelValue

    computed:
      style: ->
        style = {}
        if @notLast
          style.borderBottomLeftRadius = 0
          style.borderBottomRightRadius = 0
          style.marginBottom = '-1px'
        if @notFirst
          style.borderTopLeftRadius = 0
          style.borderTopRightRadius = 0
        style
      inputClasses: ->
        borderClasses = switch @hGroupPosition
          when 'first' then ['border-l border-t border-b rounded-l-md']
          when 'mid' then ['border-l border-t border-b']
          when 'last' then ['border rounded-r-md']
          else ['border rounded-md']
        bgClasses = if @valid then [''] else ['bg-MercuryRed-50']
        ['shadow-sm p-2 border-gray-300 focus:ring-MercuryBlue-500 focus:border-MercuryBlue-500 block w-full sm:text-sm disabled:bg-gray-50 focus:outline-none'].concat(borderClasses).concat(bgClasses)

    watch:
      modelValue: ->
        @value = @modelValue

    methods:
      emit: (e) ->
        @$emit('update:modelValue', e.target.value)
      focus: ->
        @$refs.input.focus()

    components:
      Label: Label
