
  import DialogNode from 'models/dialog_node.coffee'
  import ParameterList from './parameter_list.vue'
  import { Popover, Switch } from 'components/generic'
  import { ExclamationIcon, PlusIcon } from '@heroicons/vue/outline'
  import { ExclamationCircleIcon, LockClosedIcon, LockOpenIcon, LightningBoltIcon } from '@heroicons/vue/solid'

  export default
    props:
      node: Object
      isCurrent: Boolean

    emits: ['edit', 'add', 'editContextParameter']

    computed:
      wrapperClasses: ->
        if @node.refNode # node is only a reference to the "real" node
          return 'bg-gray-200'
        switch @node.type
          when 'action'
            if @isCurrent # node is being edited
              'rounded-full bg-MercuryYellow-600 ring-2 ring-MercuryYellow-600 shadow-md'
            else if @node.ofOtherModule # node belongs to other module
              'rounded-full bg-MercuryYellow-100'
            else # node belongs to module
              'rounded-full bg-MercuryYellow-500'
          when 'bot'
            if @isCurrent # node is being edited
              'rounded-md bg-MercuryGreen-600 ring-2 ring-MercuryGreen-600 shadow-md'
            else if @node.ofOtherModule # node belongs to other module
              'rounded-md bg-MercuryGreen-100'
            else # node belongs to module
              'rounded-md bg-MercuryGreen-500'
          when 'trigger'
            if @isCurrent # node is being edited
              'rounded-full bg-MagentaViolet-400 ring-2 ring-MagentaViolet-400 shadow-md'
            else
              'rounded-full bg-MagentaViolet-400'
          when 'user'
            if @isCurrent # node is being edited
              'rounded-md bg-MercuryBlue-600 ring-2 ring-MercuryBlue-600 shadow-md'
            else if @node.ofOtherModule # node belongs to other module
              'rounded-md bg-MercuryBlue-100'
            else # node belongs to module
              'rounded-md bg-MercuryBlue-500'
          when 'initiative'
            if @isCurrent # node is being edited
              switch @node.entity.type
                 when 'DialogAction'
                  'rounded-full bg-MercuryYellow-600 ring-2 ring-MercuryYellow-600 shadow-md'
                 when 'BotIntent'
                  'rounded-full bg-MercuryGreen-600 ring-2 ring-MercuryGreen-600 shadow-md'
            else
              'rounded-full bg-gray-200'
          when 'missing'
            'rounded-full ring-2 ring-MercuryRed-600 shadow-md'
      innerClasses: ->
        if @node.isAction || @node.isTrigger || @node.isInitiative
          'rounded-full pl-2 w-[calc(100%-0.5rem)]'
        else if @node.isBot
          'rounded-r-md -mr-2 w-[calc(100%-0.25rem)]'
        else
          'rounded-r-md w-[calc(100%-0.25rem)]'
      style: ->
        padding: '0' if @node.isDummy || @node.isMissing
        transform: 'translateY(-50%)' if @node.isMissing
        width: "#{@node.width}px" if !@node.isMissing
        height: "#{@node.height}px" if !@node.isMissing
        left: "#{@node.x}px"
        top: if @node.isMissing then "#{@node.y + @node.height / 2}px" else "#{@node.y}px"
        transition: 'top .5s, left .5s'

    methods:
      togglePrimingType: ->
        @node.entity.update(sealed: !@node.entity.sealed).save()
      edit: ->
        return if @node.isInitiative
        @$emit('edit')

    components:
      ExclamationCircleIcon: ExclamationCircleIcon
      ExclamationIcon: ExclamationIcon
      LightningBoltIcon: LightningBoltIcon
      LockClosedIcon: LockClosedIcon
      LockOpenIcon: LockOpenIcon
      ParameterList: ParameterList
      PlusIcon: PlusIcon
      Popover: Popover
      Switch: Switch
