
  import Message from 'models/message.coffee'
  import MessageVariation from 'models/message_variation.coffee'
  import MessageEditor from './message_editor.vue'
  import TemplateDropArea from './template_drop_area.vue'
  import DraggableItem from 'components/shared/draggable_item.vue'
  import Draggable from 'vuedraggable'

  export default
    props:
      modelValue: MessageVariation
      medium: String
      isResultNlg: Boolean
      # 'fixedMessage', 'mustBeFilled'

    data: ->
      variation: @modelValue
      draggingTemplate: false
      draggedTemplateType: null
      channelTypeLogos: Globals.channelTypeLogos
      messageEditorRefs: []

    computed:
      availableTemplates: ->
        templates = {}
        templateList = Message.TEMPLATES_PER_CHANNEL_TYPE[@medium] || Message.TEMPLATES_PER_CHANNEL_TYPE.default
        templateList.forEach (templateType) ->
          return unless Message.TEMPLATES[templateType]?
          templates[templateType] = Message.TEMPLATES[templateType]
        templates

    watch:
      modelValue: ->
        @variation = @modelValue

    beforeUpdate: ->
      @messageEditorRefs = []

    methods:
      cloneMessage: (index) ->
        list = @variation.messages
        list.splice(index, 0, list[index].clone())
        @update()
      removeMessage: (index) ->
        @variation.messages.splice(index, 1)
        @update()
      setMessageEditorRef: (el, index) ->
        @messageEditorRefs[index] = el if el?
      dropTemplate: (event, index) ->
        @draggingTemplate = false
        transferData = JSON.parse(event.dataTransfer.getData('value'))
        return if !transferData.template?
        @variation.addTemplateMessage(index, transferData.template)
        @update()
        @$nextTick =>
          @messageEditorRefs[index].focus()
          # scroll to new message (only after it has been expanded):
          # @$nextTick =>
          #   $messageElement = $(@$el).find('.message').last()
      update: ->
        @$emit('update:modelValue', @variation)

    components:
      Draggable: Draggable
      DraggableItem: DraggableItem
      MessageEditor: MessageEditor
      TemplateDropArea: TemplateDropArea
