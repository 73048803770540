
  import DialogModule from 'models/dialog_module.coffee'
  import Payload from 'models/payload.coffee'
  import TargetEditor from '../modules/target_editor.vue'
  import PayloadSlotEditor from './payload_slot_editor.vue'
  import { Button } from 'components/generic'

  export default
    props:
      modelValue: Payload
      useActiveBot:
        type: Boolean
        default: false

    emits: ['update:modelValue', 'close']

    data: ->
      payload: @modelValue
      targetNode: null

    computed:
      availableSlots: ->
        @targetNode?.slotDefinitions || []
      showAddSlotButton: ->
        @targetNode?
      botToUse: ->
        if @useActiveBot then @$root.activeBot else @$root.currentBot

    watch:
      modelValue: ->
        @payload = @modelValue
      'payload.target.identifier': ->
        @setTargetNode()

    created: ->
      @setTargetNode()

    methods:
      setTargetNode: ->
        @targetNode = if @payload.target.node?
          @payload.target.node
        else if @payload.target.moduleKey? && @payload.target.nodeKey?
          await @botToUse.config.getNode(@payload.target.moduleKey, @payload.target.nodeKey)
      addSlot: ->
        @payload.addSlot()
      removeSlot: (index) ->
        @payload.slots.splice(index, 1)
        @update()
      update: ->
        @$emit('update:modelValue', @payload)

    components:
      Button: Button
      PayloadSlotEditor: PayloadSlotEditor
      TargetEditor: TargetEditor
