
  import KnowledgeSource from 'models/knowledge_source.coffee'

  import DocumentAdder from './document_adder.vue'
  import { Button, Input, Label, Textarea } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      sourceLabel: String

    data: ->
      newSource = new KnowledgeSource(label: @sourceLabel)

      sourceType: newSource.type
      newSource: newSource
      files: []
      textFileName: ''
      textFileContent: ''

    methods:
      setType: (type) ->
        @sourceType = type
        @newSource.type = if type == 'text' then 'documents' else type
      addFiles: (files) ->
        @files = @files.concat(files)
      removeFile: (index) ->
        @files.splice(index, 1)
      create: ->
        if @sourceType == 'text'
          @files = [new File([@textFileContent], @textFileName)]
        @newSource.save()
          .then =>
            @newSource.upload(@files)
            @newSource
      cancel: ->
        @newSource = new KnowledgeSource(label: @sourceLabel)

    components:
      Button: Button
      DocumentAdder: DocumentAdder
      Input: Input
      Label: Label
      Textarea: Textarea
      XIcon: XIcon
