
  import Badge from './badge.vue'
  import Label from './label.vue'
  import { CheckIcon, ChevronDownIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Array
      items: Array
      label: String
      placeholder: String
      keyAttribute:
        type: String
        default: 'key'
      labelFunction:
        type: Function
        default: (item) -> item
      direction:
        type: String
        default: 'down'

    emits: ['update:modelValue', 'change']

    data: ->
      value: @modelValue
      isOpen: false
      cachedValue: []

    computed:
      valueChanged: ->
        JSON.stringify(@cachedValue) != JSON.stringify(@value.map (item) => item[@keyAttribute])

    watch:
      modelValue: ->
        @value = @modelValue
      isOpen: ->
        if @isOpen
          @setCache()
          document.addEventListener('click', @clickedOutside)
        else
          @emitChange() if @valueChanged
          document.removeEventListener('click', @clickedOutside)

    created: ->
      @setCache()

    methods:
      active: (item) ->
        @value.map((it) => it[@keyAttribute]).includes(item[@keyAttribute])
      toggleItem: (item) ->
        if @value.includes(item)
          @value = @value.filter (selectedItem) => selectedItem[@keyAttribute] != item[@keyAttribute]
        else
          @value.push(item)
        @update()
      remove: (index) ->
        @value.splice(index, 1)
        @update()
        @emitChange() if !@isOpen
      update: ->
        @$emit('update:modelValue', @value)
      emitChange: ->
        @setCache()
        @$emit('change')
      toggle: ->
        @isOpen = !@isOpen
      setCache: ->
        @cachedValue = @modelValue.map (item) => item[@keyAttribute]
      clickedOutside: (e) ->
        return if e.composedPath().includes(@$el)
        @isOpen = false

    components:
      Badge: Badge
      CheckIcon: CheckIcon
      Label: Label
      ChevronDownIcon: ChevronDownIcon
      XIcon: XIcon
