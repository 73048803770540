
  import KnowledgeSource from 'models/knowledge_source.coffee'

  import SourceCreator from './source_creator.vue'
  import { Button, Modal } from 'components/generic'

  export default
    props:
      redirect:
        type: Boolean
        default: true

    methods:
      open: ->
        @$refs.modal.open()
      create: ->
        @$refs.creator.create()
          .then (newSource) =>
            if @redirect
              @$router.push(name: 'knowledgeSource', params: {sourceId: newSource.id})
            else
              @$emit('created', newSource)
              @$refs.modal.close()
      cancel: ->
        @$refs.creator?.cancel()

    components:
      Button: Button
      Modal: Modal
      SourceCreator: SourceCreator
