
  import BotConfig from 'models/bot_config.coffee'

  import LanguageSelector from 'components/botbuilder/language_selector.vue'
  import ModuleSettingsEditor from 'components/botbuilder/modules/settings_editor.vue'
  import { Button } from 'components/generic'
  import { SearchIcon, ViewGridIcon } from '@heroicons/vue/solid'

  export default
    props:
      config: BotConfig

    emits: ['search', 'rebuild']

    computed:
      dialogModule: ->
        return if !@$route.params.moduleKey
        @config.module(@$route.params.moduleKey)

    # created: ->
    #   document.addEventListener 'keyup', (e) =>
    #     return if e.keyCode != 32
    #     @$emit('search')

    components:
      Button: Button
      LanguageSelector: LanguageSelector
      ModuleSettingsEditor: ModuleSettingsEditor
      SearchIcon: SearchIcon
      ViewGridIcon: ViewGridIcon
