
  import DialogModule from 'models/dialog_module.coffee'
  import { alert, confirm } from 'helpers'
  import { ActionMenu, Badge, Button, Input, TableCell, TableRow } from 'components/generic'
  import { PencilIcon, RefreshIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      module: DialogModule

    emits: ['remove']

    data: ->
      copy: @module.clone()
      editing: false
      actionMenuItems: [
        {icon: PencilIcon, label: 'Edit conversation flow', method: @goto}
        {icon: PencilIcon, label: 'Edit name & description', method: @edit}
        if @module.key? && @module.isCoreBehavior
          {icon: RefreshIcon, label: 'Reset', method: @remove}
        else if @module.key?
          {icon: XIcon, label: 'Delete', method: @remove}
        else
          null
      ]

    computed:
      isCoreBehaviour: ->
        @$route.name == 'coreBehaviourList'
      routerTarget: ->
        name: if @isCoreBehaviour then 'coreBehaviour' else 'module'
        params: {moduleKey: @module.key}
      descriptionInProgress: ->
        @module.description.startsWith('<auto-generation::inProgress>')
      descriptionFail: ->
        @module.description.startsWith('<auto-generation::fail>')
      formattedDescription: ->
        @module.description.replace(/<auto-generation::(inProgress|fail)>/, '')

    methods:
      goto: ->
        @$router.push(@routerTarget)
      edit: ->
        @editing = true
        @$nextTick => @$refs.labelInput.focus()
      cancel: ->
        if @module.key?
          @copy = @module.clone()
          @editing = false
        else
          @$emit('remove')
      save: ->
        @copy.saveSettings()
          .then (data) =>
            @module.update(data)
            @editing = false
            @copy = @module.clone()
          .catch (response) =>
            # alert is shown, row stays in editing mode
      remove: ->
        verb = if @module.isCoreBehavior then 'reset' else 'delete'
        return unless await confirm("Do you really want to #{verb} module “#{@module.label}”?")
        @module.botConfig.deleteDialogModule(@module)

    components:
      ActionMenu: ActionMenu
      Badge: Badge
      Button: Button
      Input: Input
      PencilIcon: PencilIcon
      TableCell: TableCell
      TableRow: TableRow
      XIcon: XIcon
