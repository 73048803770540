
  import { Button, Label } from 'components/generic'
  import { UserCircleIcon } from '@heroicons/vue/solid'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: File
      label: String
      imageUrl: String
      helpText: String
      icon:
        type: Function
        default: null

    data: ->
      file: @modelValue
      initialUrl: @imageUrl

    computed:
      objectUrl: ->
        return unless @file?
        URL.createObjectURL(@file)

    watch:
      modelValue: ->
        @file = @modelValue
      imageUrl: ->
        @initialUrl = @imageUrl

    methods:
      setFile: (e) ->
        @file = e.target.files[0]
        @update()
      remove: ->
        @file = null
        @initialUrl = null
        @update()
      update: ->
        @$emit('update:modelValue', @file)

    components:
      Button: Button
      Label: Label
      XIcon: XIcon
