
import ConditionType from 'models/condition_type.coffee'
import BooleanInput from './boolean_input.vue'
import Button from './button.vue'
import DatePicker from './date_picker.vue'
import Input from './input.vue'
import Label from './label.vue'
import Listbox from './listbox.vue'

export default
  props:
    parameter: Object
    filter: Object
    preview: Boolean
    direction: String
    context: String

  emits: ['apply']

  data: ->
    selectionValue: null

  computed:
    label: ->
      @parameter.humanizedLabel || @parameter.label
    operators: ->
      return {} if !@parameter?
      return {'=': 'equals'} if @parameter.operator == '='
      if @$root.isBotcore2
        if @context == 'inbox3'
          ConditionType.TYPES_FOR_OLD_INBOX_FILTERS
        else
          ConditionType.TYPES_FOR_OLD_FILTERS
      else
        return Object.assign({}, TypeValidator.isSetOperator(), TypeValidator.defaultOperators()) unless @parameter.type
        {'<>': foo, ...availableOperators} = Object.assign(
          {},
          TypeValidator.isSetOperator(),
          TypeValidator.availableOperators(@parameter.type)
        )
        availableOperators
    valueType: ->
      if @$root.isBotcore2
        @parameter.valueType
      else
        TypeValidator.valueType(@parameter.type)
    valueLabel: ->
      if @$root.isBotcore2
        @parameter.valueTypeLabel
      else
        TypeValidator.valueLabel(@parameter.type, @filter.operator)
    valid: ->
      if @$root.isBotcore2
        true
      else
        TypeValidator.valueValid(@filter.value, @parameter.type, @filter.operator, acceptLogicalExpressions: false)
    onlyEquals: ->
      Object.keys(@operators).length == 1 && Object.keys(@operators)[0] == '='

  created: ->
    @init()

  watch:
    'filter.operator': ->
      @filter.value = '' if @filter.operator == '?'
      @focus()
    'parameter.label': ->
      @init()
      @$nextTick =>
        @filter.value = if @valueType == 'Boolean' && @filter.operator == '=' then 'true' else ''
    'filter.field': ->
      @$nextTick => @focus()
    selectionValue: -> @filter.value = if @selectionValue? then @selectionValue.id else ''

  methods:
    init: ->
      @setSelectionValue()
      if @onlyEquals
        @filter.operator = '='
      if @parameter.operator?
        @filter.operator = @parameter.operator
    apply: ->
      @$emit('apply')
    focus: ->
      Vue.nextTick =>
        return unless @$refs.valueInput?
        @$refs.valueInput.focus()
    setSelectionValue: ->
      if @parameter.type != 'Selection'
        @selectionValue = null
        return
      @selectionValue = @parameter.collection.find((item) => item.id == @filter.value) || {name: 'select'}

  components:
    BooleanInput: BooleanInput
    Button: Button
    DatePicker: DatePicker
    Input: Input
    Label: Label
    Listbox: Listbox
