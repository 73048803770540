
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import Condition from 'models/condition'
  import KnowledgeSource from 'models/knowledge_source.coffee'
  import { confirm } from 'helpers'

  import ConditionEditor from 'components/botbuilder/condition_editor.vue'
  import DocumentAdder from './document_adder.vue'
  import DocumentRow from './document_row.vue'
  import StatusIcon from './status_icon.vue'
  import { ActionMenu, BackLink, Button, H3, H4, Input, Label, PageHeader, Textarea } from 'components/generic'
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
  import { ChevronDownIcon, PlusIcon, RefreshIcon, XIcon } from '@heroicons/vue/outline'

  export default
    data: ->
      source: null
      documents: null
      pollingTimeout: null
      searchText: ''
      debounceTimeout: null
      textFileName: ''
      textFileContent: ''
      uploading: false
      PlusIcon: PlusIcon

    computed:
      actionMenuItems: ->
        [
          {icon: RefreshIcon, label: 'Recrawl', method: @recrawl} if @source?.type == 'http_crawler'
          {icon: XIcon, label: 'Delete', method: @remove}
        ].compact()

    created: ->
      @source = await KnowledgeSource.load(@$route.params.sourceId)
      window.breadcrumbs.strip()
        .append(text: 'Knowledge', callback: => @$router.push(name: 'knowledge'))
        .append(text: @source.label)
      @updateAndPoll()

    unmounted: ->
      clearTimeout(@pollingTimeout)

    methods:
      loadDocuments: ->
        @documents = await @source.documents(text: @searchText)
      reloadDocuments: ->
        @loadDocuments()
        @poll()
      uploadFiles: (files) ->
        @uploading = true
        @source.upload(files)
          .then =>
            @searchText = ''
            @reloadDocuments()
          .finally =>
            @uploading = false
      removeDocument: (doc) ->
        return unless await confirm("Do you really want do delete “#{doc.filename}” from this knowledge source?")
        @source.deleteDocument(doc.id)
          .then(@reloadDocuments)
      uploadText: ->
        files = [new File([@textFileContent], @textFileName)]
        @uploadFiles(files)
          .then =>
            @textFileName = ''
            @textFileContent = ''
      remove: ->
        return unless await confirm("Do you really want do delete the knowledge source “#{@source.label}”?")
        @source.delete()
          .then => @$router.push(name: 'knowledge')
      recrawl: ->
        @source.recrawl()
          .then(@poll)
      addCondition: ->
        @source.activeIf = new Condition()
      removeCondition: ->
        @source.activeIf = null
        @update()
      updateAndPoll: (data) ->
        @source.updateStatusModel(data)
        @loadDocuments()
        if @source.inProgress
          @pollingTimeout = setTimeout(@poll, 3000)
      poll: ->
        return if !@source.id
        BotbuilderApi.getKnowledgeSourceStatus(@source.id)
          .then (data) => @updateAndPoll(data)
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout((=> @source.save()), 1000)

    components:
      ActionMenu: ActionMenu
      BackLink: BackLink
      Button: Button
      ChevronDownIcon: ChevronDownIcon
      ConditionEditor: ConditionEditor
      Disclosure: Disclosure
      DisclosureButton: DisclosureButton
      DisclosurePanel: DisclosurePanel
      DocumentAdder: DocumentAdder
      DocumentRow: DocumentRow
      H3: H3
      H4: H4
      Input: Input
      Label: Label
      PageHeader: PageHeader
      StatusIcon: StatusIcon
      RefreshIcon: RefreshIcon
      Textarea: Textarea
