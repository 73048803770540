
  import ContextInterface from 'models/context_interface.coffee'
  import DialogNode from 'models/dialog_node.coffee'
  import KnowledgeSource from 'models/knowledge_source.coffee'
  import Verbalization from 'models/verbalization.coffee'
  import WorkflowStep from 'models/workflow_step.coffee'
  import WorkflowTrigger from 'models/workflow_trigger'

  import { Combobox } from 'components/generic'

  export default
    props:
      modelValue: String
      resourceTypes: Array
      host: [DialogNode, ContextInterface, KnowledgeSource, Verbalization, WorkflowStep, WorkflowTrigger]
      label: String
      expressionWrap:
        type: Boolean
        default: false
      fixedItems:
        type: Array
        default: []
      disabled:
        type: Boolean
        default: false

    emits: ['update:modelValue', 'set-variable', 'set-component']

    data: ->
      originalReference: @modelValue
      reference: ''
      variable: null
      possibleVariables: []
      selectingComponent: false
      component: null

    computed:
      collection: ->
        if @fixedItems.length > 0
          @fixedItems.concat(@possibleVariables)
        else
          [key: null, label: '< none >'].concat(@possibleVariables)
      components: ->
        @variable?.components || []

    watch:
      modelValue: ->
        @init()
      variable: ->
        @$emit('set-variable', @variable)
      component: ->
        @$emit('set-component', @component)

    created: ->
      @setPossibleVariables()
        .then =>
          @init()

    methods:
      setPossibleVariables: ->
        @possibleVariables = if @host instanceof WorkflowStep || @host instanceof WorkflowTrigger
          []
        else
          @$root.contextParameters.slice()
        return unless @host?.getVariables?
        @possibleVariables = (await @host.getVariables()).concat(@possibleVariables)
      init: ->
        @reference = if @expressionWrap && match = @originalReference.match(/^\$\{(\S+)\}$/)
          match[1]
        else if @expressionWrap
          ''
        else
          @originalReference
        @variable = if !@reference
          null
        else
          @collection.find (variable) => variable.reference == @reference.split('#')[0]
        componentKey = @reference.split('#')[1]
        @component = (@variable?.components || []).find (component) -> component.key == componentKey
      update: ->
        @originalReference = if @variable?.reference && @component?.key?
          @variable.reference + '#' + @component.key
        else if @variable?.reference
          @variable.reference
        else
          ''
        if @expressionWrap && @originalReference
          @originalReference = "${#{@originalReference}}"
        @$emit('update:modelValue', @originalReference)
      updateVariable: ->
        @component = null
        if @variable.components?
          @selectingComponent = true
          @$nextTick => @$refs.componentCombobox.open()
        else
          @update()
      updateComponent: ->
        return if !@component?.key?
        @selectingComponent = false
        @contextParameterKey += '#' + @component.key
        @update()
      cancel: ->
        @selectingComponent = false
        @init()
      searchVariables: (query) ->
        (
          if @host?.searchVariables
            @host.searchVariables(query, types: @resourceTypes)
          else if @host instanceof WorkflowStep || @host instanceof WorkflowTrigger
            Promise.resolve(
              @possibleVariables.filter (variable) ->
                variable.key.startsWith(query) || variable.reference.startsWith(query)
            )
          else
            @$root.currentBot.config.searchContextParameters(query)
        ).then (items) => @fixedItems.concat(items)
      searchComponent: (query) ->
        pieces = query.split(' ')
        Promise.resolve(
          @components.filter (cmp) ->
            pieces.some (piece) ->
              cmp.key.toLowerCase().includes(piece) || cmp.label.toLowerCase().includes(piece)
        )
      variableLabel: (variable) ->
        return @reference if !variable?
        variable.label
      componentLabel: (component) ->
        return '' if !component
        component.label
      variableAndComponentLabel: ->
        return @reference if !@variable?
        if @component?
          @component.fullLabel
        else
          @variableLabel(@variable)
      focus: ->
        @$refs.variableCombobox?.focus()
      open: ->
        @$refs.variableCombobox?.open()

    components:
      Combobox: Combobox
