import GuidGenerator from 'models/guid_generator.coffee'
import Attachment from 'models/attachment.coffee'
import Message from 'models/message.coffee'
import Verbalization from 'models/verbalization.coffee'

export default class Bubble
  constructor: (@message, data = {}) ->
    @id = GuidGenerator.newGuid()
    @attachments = (data.attachments || []).map (attachmentData) => new Attachment(@, attachmentData)

  removeAttachment: (id) ->
    @attachments = @attachments.filter (attachment) => attachment.id != id

  addButton: ->
    return unless @canHaveButtons
    if @canHaveProvideValueButtons
      @attachments.push(new Attachment(@, type: 'ProvideValueButton', pristine: true))
    else
      @attachments.push(new Attachment(@, type: 'PostbackButton', pristine: true))

  addQuickReplyButton: ->
    return unless @message.canHaveQuickreplies
    if @canHaveProvideValueButtons
      @attachments.push(new Attachment(@, type: 'ProvideValueButton', pristine: true))
    else
      @attachments.push(new Attachment(@, type: 'QuickReplyButton', pristine: true))

  addWhatsappListRow: (index) ->
    return if @message.templateType != 'whatsappList'
    @attachments.splice(
      index * 3 + 1, # first attachment doesn't count
      0,
      new Attachment(@, type: 'Text', textRole: 'TITLE'),
      new Attachment(@, type: 'Text', textRole: 'SUBTITLE'),
      new Attachment(@, type: 'PostbackButton'),
    )

  removeWhatsappListRow: (index) ->
    return if @message.templateType != 'whatsappList'
    return if @attachments.length <= 4
    return if index > (@attachments.length - 1) / 3 - 1
    @attachments.splice(index * 3 + 1, 3)

  Object.defineProperties @prototype,
    export:
      get: ->
        attachments: @attachments.map (attachment) -> attachment.export
    buttons:
      get: ->
        @attachments.filter (attachment) -> attachment.isButton
    nonButtonAttachments:
      get: ->
        @attachments.filter (attachment) -> !attachment.isButton
    nonQuickreplyButtons:
      get: ->
        return [] if @message.canHaveQuickreplies
        @buttons
    canHaveButtons:
      get: ->
        if @message.templateType == 'whatsappList'
          !@isFirst
        else
          canHaveButtons = @message.templateConfig.canHaveButtons
          return false if !canHaveButtons
          return true if canHaveButtons == true
          canHaveButtons.includes(@message.medium)
    maxButtons:
      get: ->
        return 0 if !@canHaveButtons
        if @message.valueFor('maxButtons')?
          @message.valueFor('maxButtons')
        else
          99
    mayAddButton:
      get: ->
        return false if !@canHaveButtons
        @buttons.length < @maxButtons
    canHaveProvideValueButtons:
      get: ->
        @message.host instanceof Verbalization && [
          'QueryVerbalization',
          'RetryVerbalization',
          'ConfirmExistingVerbalization',
          'ConfirmUnderstoodVerbalization',
        ].includes(@message.host.type)
    isFirst:
      get: ->
        0 == @message.bubbles.findIndex (bubble) => bubble.id == @id
    valid:
      get: ->
        return false if @attachments.length == 0
        @attachments.every (attachment) -> attachment.valid
    dialogModule:
      get: -> @message.dialogModule
    dialogNode:
      get: -> @message.dialogNode
    host:
      get: -> @message.host
    whatsappListRows:
      get: ->
        return if @message.templateType != 'whatsappList'
        return if @isFirst
        [0..((@attachments.length - 1) / 3 - 1)].map (index) =>
          @attachments[(index * 3 + 1)..(index * 3 + 3)]
