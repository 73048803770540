
  import EntitySchema from 'models/entity_schema.coffee'

  import SchemaTable from './schema_table.vue'
  import Wrapper from './wrapper.vue'
  import { Button, Input, Modal } from 'components/generic'

  export default
    data: ->
      newSchema: new EntitySchema()
      creating: false

    computed:
      createDisabled: ->
        @creating || !@newSchema.label

    created: ->
      window.breadcrumbs.strip().append(text: 'Content schema')

    methods:
      cancel: ->
        @newSchema = new EntitySchema()
      create: ->
        @creating = true
        @newSchema.create()
          .then =>
            @$root.entitySchemas.push(@newSchema)
            @$router.push(name: 'entitySchema', params: {schemaKey: @newSchema.key})

    components:
      Button: Button
      Input: Input
      Modal: Modal
      SchemaTable: SchemaTable
      Wrapper: Wrapper
