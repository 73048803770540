
  import ContextParameter from 'models/context_parameter.coffee'

  import ValidationsEditor from './validations_editor.vue'
  import DataTypeDependentInput from 'components/botbuilder/inputs/data_type_dependent_input.vue'
  import DataTypeEditor from 'components/botbuilder/data_type_editor.vue'
  import { Button, H1, Combobox, Input, Label, Panel, Switch } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      contextParameter: ContextParameter

    emits: ['close']

    data: ->
      copy: @contextParameter.clone()
      categories: []
      expectedValueInputRefs: []
      PlusIcon: PlusIcon

    created: ->
      @setCategories()

    beforeUpdate: ->
      @expectedValueInputRefs = []

    methods:
      setCategories: (query) ->
        @categories = Array.unique(
          [query].concat(
            @$root.contextParameters.map (cp) -> cp.category
          ).filter (category) -> category?
        ).sort()
      filterCategories: (category, query)->
        category.toLowerCase().startsWith(query.toLowerCase())
      cancel: ->
        if @contextParameter.pristine
          @$router.push(name: 'contextParameterList')
        else
          @$emit('close')
      save: ->
        if @contextParameter.pristine
          @contextParameter.update(@copy.export).create()
            .then =>
              @$root.contextParameters.push(@contextParameter)
              @$router.push(name: 'contextParameter', params: {parameterKey: @contextParameter.key})
        else
          @contextParameter.update(@copy.export).save()
            .then (data) => @contextParameter.update(data)
        @$emit('close')
      addExpectedValue: ->
        @copy.expectedValues.push('')
        @$nextTick =>
          @expectedValueInputRefs.last.focus()
      setExpectedValueInputRef: (el) ->
        @expectedValueInputRefs.push(el) if el?

    components:
      Button: Button
      Combobox: Combobox
      DataTypeDependentInput: DataTypeDependentInput
      DataTypeEditor:DataTypeEditor
      H1: H1
      Input: Input
      Label: Label
      Panel: Panel
      Switch: Switch
      ValidationsEditor: ValidationsEditor
      XIcon: XIcon
