<template>
  <div
    :class="{'with-error': showError}"
    class="w-full"
  >
    <TextEditor
      v-if="attachment.type == 'Text'"
      v-model="attachment.text"
      :node="attachment.message.host"
      @focus="edit"
      @exit="endEditing"
      @blur="endEditing"
      @update:modelValue="update"
      :placeholder="placeholder"
      :class="roundedClass"
      :max-length="maxLength"
      ref="textEditor"
    />

    <MediaAttachmentEditor
      v-else-if="['Image', 'Video'].includes(attachment.type)"
      v-model="attachment"
      @update:modelValue="update"
      :class="roundedClass"
    />

  </div>
</template>

<script lang="coffee">
  import Attachment from 'models/attachment.coffee'
  import Verbalization from 'models/verbalization.coffee'

  import MediaAttachmentEditor from './media_attachment_editor.vue'
  import TextEditor from './text_editor.vue'
  import UniversalInput from 'components/botbuilder/inputs/universal_input.vue'
  import { Editable, Input, Listbox } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Attachment
      index:
        type: Number
        default: 0
      count:
        type: Number
        default: 1
      maxLength:
        type: Number
        default: null

    emits: ['update:modelValue', 'remove']

    data: ->
      attachment: @modelValue
      editing: false

    computed:
      showError: ->
        !@attachment.pristine && !@attachment.valid
      showPath: ->
        @editing || @attachment.path && !@attachment.pathValid
      placeholder: ->
        return unless @attachment.type == 'Text'
        switch @attachment.textRole
          when 'NONE' then 'Enter text here'
          when 'TITLE' then 'Enter headline here'
          when 'SUBTITLE' then 'Enter subline here'
      roundedClass: ->
        if @count == 1
          'rounded-lg'
        else if @index == 0
          'rounded-t-lg'
        else if @index == @count - 1
          'rounded-b-lg'
        else
          ''
      contextParameter: ->
        return unless @attachment.host instanceof Verbalization
        @$root.contextParameters.find (cp) => cp.key == @attachment.host.parameterKey

    watch:
      modelValue: ->
        @attachment = @modelValue
        @setStuff()
      editing: ->
        return if !@attachment.isButton
        if @editing
          # end editing mode when clicked outside
          setTimeout(=> # need this, otherwise the click that enabled editing mode triggers the handler
            $(document).on "click.#{@attachment.id}", (event) =>
              if $(event.target).closest(".attachment.#{@attachment.type.toLowerCase()}").get(0) != @$el
                @endEditing()
              true
          , 0)
        else
          $(document).off "click.#{@attachment.id}"

    mounted: ->
      @editing = true if @attachment.pristine

    unmounted: ->
      $(document).off "click.#{@attachment.id}"

    methods:
      edit: ->
        @editing = true
      endEditing: ->
        @editing = false
        @attachment.pristine = false
        @attachment.bubble.message.pristine = false
      update: ->
        @$emit('update:modelValue', @attachment)
      focus: ->
        @editing = true
        @$nextTick =>
          if @$refs.textEditor?
            @$refs.textEditor.focus()
          else if @$refs.urlInput?
            @$refs.urlInput.focus()

    components:
      Editable: Editable
      Input: Input
      Listbox: Listbox
      MediaAttachmentEditor: MediaAttachmentEditor
      TextEditor: TextEditor
      UniversalInput: UniversalInput
      XIcon: XIcon
</script>
