
  import { Label, Slider } from 'components/generic'

  hexRegexWithAlpha = /^#((([0-9A-Fa-f]){4})|(([0-9A-Fa-f]{2}){4}))$/

  export default
    props:
      modelValue:
        type: String
        default: '#000000'
      name:
        type: String
        default: 'Color'
      label:
        type: String
        default: 'Color'

    data: ->
      isOpen: false
      colors: [
        '#000000',
        '#808080',
        '#c0c0c0',
        '#ffffff',
        '#ff0000',
        '#800000',
        '#ffff00',
        '#808000',
        '#00ff00',
        '#008000',
        '#00ffff',
        '#008080',
        '#0000ff',
        '#000080',
        '#ff00ff',
        '#800080',
      ]
      hexColor: @parseColor(@modelValue).color
      selectedColor: @parseColor(@modelValue).color
      opacity: @parseColor(@modelValue).opacity

    watch:
      opacity: ->
        @update()
      isOpen: ->
        if @isOpen
          document.addEventListener('click', @clickedOutside)
        else
          document.removeEventListener('click', @clickedOutside)

    methods:
      selectColor: (color) ->
        @selectedColor = color
        @update()
        @isOpen = false
      updateColor: ->
        hexRegex = /^#([0-9A-Fa-f]{3}){1,2}$/g
        if hexRegex.test(@hexColor)
          @selectedColor = @hexColor
          @$emit('update:modelValue', @hexColorWithAlpha(@hexColor))
      update: ->
        @$emit('update:modelValue', @hexColorWithAlpha(@selectedColor))
      hexColorWithAlpha: (color) ->
        if color.length == 4
          color + Math.floor(@opacity / 100 * 15).toString(16)
        else if color.length == 7
          color + Math.floor(@opacity / 100 * 255).toString(16)
      parseColor: (color) ->
        if match = @modelValue.match(hexRegexWithAlpha)
          if match[3]
            color: color[0..3]
            opacity: Math.floor(parseInt(match[3], 16) / 15 * 100)
          else if match[5]
            color: color[0..6]
            opacity: Math.floor(parseInt(match[5], 16) / 255 * 100)
        else
          color: color
          opacity: 100
      clickedOutside: (e) ->
        return if e.composedPath().includes(@$el)
        @isOpen = false

    components:
      Label: Label
      Slider: Slider
