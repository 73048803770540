
  import Workflow from 'models/workflow.coffee'
  import WorkflowTrigger from 'models/workflow_trigger.coffee'
  import { Input, Listbox, Panel } from 'components/generic'

  export default
    props:
      modelValue: Workflow

    emits: ['update:modelValue', 'close']

    data: ->
      triggerTypes = Object.entries(WorkflowTrigger.TYPES).map ([key, config]) -> {id: key, name: config.label}

      workflow: @modelValue.clone()
      triggerTypes: triggerTypes
      triggerType: triggerTypes[0]

    watch:
      triggerType: ->
        return if !@triggerType?
        @workflow.triggerType = @triggerType.id

    methods:
      save: ->
        @$emit('close')
        if @workflow.pristine
          @workflow.save()
            .then => @$router.push(name: 'workflow', params: {workflowId: @workflow.id})
        else
          @$emit('update:modelValue', @workflow)
      cancel: ->
        if @workflow.pristine
          @$router.push(name: 'workflowList')
        else
          @$emit('close')

    components:
      Input: Input
      Listbox: Listbox
      Panel: Panel
