import { createApp } from 'vue_shims'
import { createWebHistory, createRouter } from 'vue-router'

import AnswerTemplate from 'models/answer_template'
import BotbuilderApi from 'models/botbuilder_api'
import ParameterFilter from 'models/parameter_filter'
import PlatformApi from 'models/platform_api'
import PlatformApi2 from 'models/platform_api2'
import InboxApi from 'models/inbox_api'
import ContextParameterHelper from 'mixins/context_parameter_helper'
import { trackRouting } from 'helpers'

import ConversationList from 'components/inbox3/conversation_list.vue'
import MessageList from 'components/inbox3/message_list.vue'
import NotificationPermissionModal from 'components/inbox3/notification_permission_modal.vue'
import RightSidebar from 'components/inbox3/right_sidebar.vue'
import { Alert, Button } from 'components/generic'


initInbox3App = (element) ->
  elementData = JSON.parse(element.getAttribute('data'))
  router = createRouter(
    history: createWebHistory()
    routes: [
      name: 'inbox'
      path: '/projects/:projectId/:stageLevel/inbox3/:tab?/:conversationId?'
      component: MessageList
    ]
  )
  trackRouting(router)

  createApp(
    mixins: [ContextParameterHelper]

    data: ->
      mayAnswerInbox = Globals.currentUser.may('edit_inbox', Globals.project, stageLevel: Globals.stageLevel)
      mayViewForeignConversations = Globals.currentUser.may('view_foreign_conversations', Globals.project, stageLevel: Globals.stageLevel)
      tabs = [
        {key: 'chat', label: t('inbox.tabs.chat'), description: t('inbox.tabs.chatDescription')} if mayAnswerInbox
        {key: 'overview', label: t('inbox.tabs.overview'), description: t('inbox.tabs.overviewDescription')}
        {key: 'logs', label: t('inbox.tabs.logs'), description: t('inbox.tabs.logsDescription')} if mayViewForeignConversations
      ].filter (tab) -> tab?
      userSettings = elementData.settings || {}

      isBotcore2: true
      errorMessage: elementData.error_message
      agent: null
      agents: []
      agentTeams: []
      api: new InboxApi(
        elementData.session,
        Globals.project,
        Globals.stageLevel,
        (reconnecting) => @reconnecting = reconnecting
      )
      cobrowsingSessionAvailable: elementData.cobrowsing_session_available
      mayAnswerInbox: mayAnswerInbox
      mayViewForeignConversations: mayViewForeignConversations
      channels: []
      contextParameters: []
      answerTemplates: []
      answerTemplateTags: []
      initialized: false
      singleConversationId: null
      tabs: tabs
      currentTab: tabs[0]
      currentConversation: null
      selectedAnswerTemplate: null
      reconnecting: false
      showSidebar: false
      userSettings: userSettings
      parameterFilters: (userSettings.filters || []).map (filter) -> new ParameterFilter(filter)
      filters:
        textSearch: userSettings.textSearch
        assignedTo: userSettings.assignedTo
        activeHandover: false
        wasInHandover: false
      inbox2Url: Routes.inbox2_project_path(Globals.projectId, Globals.stageLevel)

    computed:
      filtersForApi: ->
        if @singleConversationId
          return
            stageIds: elementData.stage_ids
            contextParameters: [new ParameterFilter(field: 'user.id', value: @singleConversationId)]

        channelFilter = @parameterFilters.find (filter) -> filter.field == 'channel'
        fixParameters = @parameterFilters.filter (filter) -> filter.fix
        if @filters.wasInHandover
          fixParameters.push(field: 'wasInHandover', value: true)

        stageIds: elementData.stage_ids
        channel: {uuid: channelFilter?.value}
        timestamp: null
        assignedTo: @filters.assignedTo
        textSearch: @filters.textSearch
        activeHandover: @filters.activeHandover
        missingAnnotations: false
        fixParameters: fixParameters
        contextParameters: @parameterFilters.filter (filter) -> !filter.fix

    created: ->
      window.breadcrumbs.enableEnvironmentSelector()
      window.breadcrumbs.append(
        text: 'Inbox'
        callback: => @$router.push(name: 'inbox')
        rails: true
      )
      # currentTab will be set via watcher
      @loadChannels()
      @loadContextParameters()
      @loadAgents()

    watch:
      '$route.params.tab': ->
        if @$route.params.tab
          @currentTab = @tabs.find((tab) => tab.key == @$route.params.tab) || @tabs[0]
        if !@initialized
          @initialized = true
          if @$route.hash
            @singleConversationId = @$route.hash[1..]
            @$refs.conversationList.start()
            return
        if @$route.params.tab
          @initTab()
        else
          @$router.push(params: {tab: @userSettings.tab || @tabs[0].key})
      currentConversation: ->
        @showSidebar = false
        @selectedAnswerTemplate = null
        @setBreadcrumbs()
        if @currentConversation?
          @$router.push(hash: "##{@currentConversation.id}")
          @loadAnswerTemplates()
        else
          @$router.push(hash: '')
      reconnecting: (newValue, oldValue) ->
        @reload() if oldValue && !newValue

    mounted: ->
      @setHeight()
      window.addEventListener('resize', @setHeight)

    beforeUnmount: ->
      @$root.api.halt()

    unmounted: ->
      window.removeEventListener('resize', @setHeight)

    methods:
      loadChannels: ->
        PlatformApi2.getChannels().then (channels) =>
          @channels = channels.sortByString('label').map (ch) -> {id: ch.id, name: ch.label}
      loadContextParameters: ->
        @contextParameters = await BotbuilderApi.getContextParameters()
      loadAgents: ->
        PlatformApi.Handover.getAgent()
          .then (agent) => @agent = agent
          .catch => null
        PlatformApi.Handover.getAgents()
          .then (agents) => @agents = agents
          .catch => null
        PlatformApi.Handover.getTeams()
          .then (teams) => @agentTeams = teams
          .catch => null
      loadAnswerTemplates: ->
        @api.loadAnswerTemplates(@currentConversation.id)
          .then (templatesData) =>
            @answerTemplates = templatesData
              .map (data) -> new AnswerTemplate(data)
              .sortByString('lowerCaseExampleQuestion')
        @api.loadAnswerTemplateTags(@currentConversation.id)
          .then (tags) => @answerTemplateTags = tags
      setBreadcrumbs: ->
        window.breadcrumbs.strip()
          .append(
            text: @currentTab.label,
            callback: => @currentConversation = null
          )
        if @currentConversation?
          window.breadcrumbs.append(text: @currentConversation.fullName)
      setHeight: ->
        @$refs.main.style.height = document.documentElement.clientHeight -
          @$refs.main.getBoundingClientRect().top + 'px'
      initTab: ->
        if @currentTab.key == 'chat'
          @filters.activeHandover = true
        else
          @filters.activeHandover = false
        if @currentTab.key == 'overview'
          @filters.wasInHandover = true
        else
          @filters.wasInHandover = false
        if @currentTab.key == 'logs'
          @filters.assignedTo = null
        @saveSettings()
        @$refs.conversationList.start()
        @currentConversation = null
        @setBreadcrumbs()
      saveSettings: ->
        PlatformApi.Webapp.saveProjectSettings(
          Globals.projectId,
          Globals.stageLevel,
          inbox3:
            tab: @currentTab.key
            textSearch: @filters.textSearch
            assignedTo: @filters.assignedTo
            filters: @parameterFilters
        )
      backToListMode: ->
        @singleConversationId = null
        @initTab()
      reload: ->
        @$refs.conversationList.start()
        @currentConversation?.load(@api)

    components:
      Alert: Alert
      Button: Button
      ConversationList: ConversationList
      MessageList: MessageList
      NotificationPermissionModal: NotificationPermissionModal
      RightSidebar: RightSidebar

    template: '
      <div v-show="reconnecting" class="my-4 text-center text-base font-normal">{{ t("inbox.reconnecting") }}</div>
      <div ref="main" class="flex h-full w-[100vw] overflow-hidden">
        <div v-if="errorMessage" class="w-full flex justify-center items-start pt-12">
          <Alert>{{ errorMessage }}</Alert>
        </div>
        <template v-else>
          <ConversationList
            v-model="currentConversation"
            :single-conversation-id="singleConversationId"
            :class="[currentConversation ? \'-ml-[100vw]\' : \'\', \'w-[100vw] lg:ml-0 lg:w-96 flex-shrink-0\']"
            @unset-conversation="backToListMode"
            @update-filters="saveSettings"
            ref="conversationList"
          />
          <MessageList
            :conversation="currentConversation"
            @show-sidebar="showSidebar = true"
            @unset-conversation="currentConversation = null"
            @set-answer-template="selectedAnswerTemplate = $event"
            @reset-answer-template="selectedAnswerTemplate = null"
            :class="[currentConversation ? \'min-w-[100vw] max-w-[100vw] lg:min-w-0 4xl:min-w-5xl lg:max-w-5xl w-full flex-grow\' : \'w-full\']"
          />
          <RightSidebar
            :conversation="currentConversation"
            :open="showSidebar"
            @close="showSidebar = false"
            @set-answer-template="selectedAnswerTemplate = $event"
            @unset-conversation="currentConversation = null"
          />
        </template>
      </div>
      <NotificationPermissionModal/>
      <Button type="primary" size="sm" :href="inbox2Url" class="absolute right-1 top-[4.25rem] z-30">
        {{ t(\'inbox.goToLegacyInbox\') }}
      </Button>
    '
  )
    .use(router)
    .mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('inbox3-app')
      initInbox3App(element)
