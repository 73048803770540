
  import KnowledgeSource from 'models/knowledge_source.coffee'

  import { ActionMenu, Alert, H4, Spinner } from 'components/generic'
  import { ExternalLinkIcon, UploadIcon, XIcon } from '@heroicons/vue/outline'

  export default
    props:
      source: KnowledgeSource
      doc: Object

    emits: ['remove', 'replaced']

    data: ->
      errorMessage: null
      actionMenuItems: [
        {icon: ExternalLinkIcon, label: 'View', method: @openDocument}
        {icon: UploadIcon, label: 'Replace', method: @replace} if @source.type == 'documents'
        {icon: XIcon, label: 'Delete', method: => @$emit('remove', @doc)}
      ].compact()

    methods:
      openDocument: ->
        @source.getDocumentUrl(@doc.id)
          .then (url) => window.open(url, '_blank')
      replace: ->
        @$refs.fileInput.click()
      uploadFile: (e) ->
        @source.replaceDocument(@doc.id, e.target.files[0])
          .then => @$emit('replaced', @doc)

    components:
      ActionMenu: ActionMenu
      Alert: Alert
      UploadIcon: UploadIcon
      H4: H4
      Spinner: Spinner
