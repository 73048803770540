import BotbuilderApi from 'models/botbuilder_api.coffee'
import DialogNode from 'models/dialog_node.coffee'
import TriggerIntent from './trigger_intent.coffee'
import TriggerSettings from 'models/trigger_settings.coffee'
import UserIntent from './user_intent.coffee'
import Action from './dialog_action.coffee'
import BotIntent from './bot_intent.coffee'

export default class DialogModule
  CORE_BEHAVIOUR_TYPES: [
    'ActiveLearning'
    'Inactivity'
    'Knowledge'
    'Media'
    'OptInLegalPrivacy'
    'Recovery'
  ]

  constructor: (@botConfig, data) ->
    @metainfo = {}
    @nodeMeta = {}
    @update(data)

  update: (data = {}) ->
    data ||= {}
    @key = data.key
    @type = data.type
    @label = data.label || ''
    @description = data.description || ''
    @triggerSettings = new TriggerSettings(@, data.triggerSettings)
    @nodeIndex = {}
    (data.nodes || []).forEach (nodeData) =>
      newNode = DialogNode.typed(@, nodeData)
      @addtoNodeIndex(newNode)
    this

  saveSettings: ->
    if @key?
      BotbuilderApi.updateModuleSettings(@)
    else
      BotbuilderApi.createModule(@)

  clone: ->
    new DialogModule(@botConfig, @export)

  setMetainfo: (metainfo) ->
    @metainfo = metainfo
    (metainfo.nodeMetaInfos || []).forEach (nodeMeta) =>
      @nodeMeta[nodeMeta.nodeKey] = nodeMeta
      @nodeMeta[nodeMeta.nodeKey].externalTargetNodes = (nodeMeta.externalTargetNodes || []).map (nodeInfo) =>
        DialogNode.typed(@botConfig.module(nodeInfo.moduleKey), nodeInfo.node)
      @nodeMeta[nodeMeta.nodeKey].externalIncomingNodes = (nodeMeta.externalIncomingNodes || []).map (nodeInfo) =>
        DialogNode.typed(@botConfig.module(nodeInfo.moduleKey), nodeInfo.node)

  saveMetainfo: ->
    BotbuilderApi.updateModuleMetaInfo(@)

  addtoNodeIndex: (node) ->
    @nodeIndex[node.key] = node

  addUserIntent: (data = {}) ->
    userIntent = new UserIntent(@, data)
    userIntent.save()
      .then => @addtoNodeIndex(userIntent)
  addAction: (data = {}) ->
    action = new Action(@, data)
    action.save()
      .then => @addtoNodeIndex(action)
  addBotIntent: (data = {}) ->
    botIntent = new BotIntent(@, data)
    botIntent.save()
      .then => @addtoNodeIndex(botIntent)

  updateNodes: (affectedNodeReferences) ->
    promises = affectedNodeReferences.map (reference) =>
      return Promise.resolve() if reference.moduleKey != @key
      BotbuilderApi.getNode(@botId, @, reference.nodeKey)
        .then (data) =>
          @nodeIndex[reference.nodeKey].update(data)
        .catch (response) =>
          @removeNode(reference.nodeKey) if response.status == 404
    Promise.all(promises)
  removeNode: (nodeKey) ->
    delete @nodeIndex[nodeKey]

  searchNodes: (query, {types, node} = {}) =>
    BotbuilderApi.search(@botId, query, moduleKey: @key, types: types, node: node)
      .then (data) =>
        data.map (matchData) =>
          DialogNode.typed(@, matchData.entity)

  clearNodes: ->
    @nodeIndex = {}

  Object.defineProperties @prototype,
    botId:
      get: -> @botConfig.id
    nodes:
      get: ->
        Object.values(@nodeIndex)
    isCoreBehavior:
      get: ->
        @CORE_BEHAVIOUR_TYPES.includes(@type)
    actions:
      get: ->
        @nodes.filter (n) -> n.type == 'DialogAction'
    botIntents:
      get: ->
        @nodes.filter (n) -> n.type == 'BotIntent'
    triggerIntents:
      get: ->
        @nodes.filter (n) -> n.type == 'TriggerIntent'
    userIntents:
      get: ->
        @nodes.filter (n) -> n.type == 'UserIntent'
    triggerAndUserIntents:
      get: ->
        @nodes.filter (n) -> n.type == 'TriggerIntent' || n.type == 'UserIntent'
    actionsAndBotIntents:
      get: ->
        @nodes.filter (n) -> n.type == 'BotIntent' || n.type == 'DialogAction'
    nonBotNodes:
      get: ->
        @nodes.filter (n) -> n.type != 'BotIntent'
    export:
      get: ->
        key: @key
        type: @type
        label: @label
        description: @description
        triggerSettings: @triggerSettings.export
        nodes: @nodes.map (node) -> node.export
    metainfoExport:
      get: ->
        showExternalNodes: @metainfo.showExternalNodes
        groupExternalNodes: @metainfo.groupExternalNodes
