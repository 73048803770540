import { createApp } from 'vue_shims'
import { createWebHistory, createRouter } from 'vue-router'
import Date from 'patches/date'
import AnalyticsData from 'models/analytics_data.coffee'
import Bot from 'models/bot.coffee'
import ParameterFilter from 'models/parameter_filter.coffee'
import BotbuilderApi from 'models/botbuilder_api.coffee'
import PlatformApi from 'models/platform_api.coffee'
import PlatformApi2 from 'models/platform_api2.coffee'
import { trackRouting } from 'helpers'

import Menu from 'components/shared/sidebar_menu.vue'
import UpgradePrompt from 'components/shared/upgrade_prompt.vue'
import TimeSettings from 'components/analytics2/time_settings.vue'
import FilterSidebar from 'components/analytics2/filter_sidebar.vue'
import Messages from 'components/analytics2/messages.vue'
import Users from 'components/analytics2/users.vue'
import Handovers from 'components/analytics2/handovers.vue'
import LivechatSessions from 'components/analytics2/livechat_sessions.vue'
import Retention from 'components/analytics2/retention.vue'
import Intents from 'components/analytics2/intents.vue'
import Channels from 'components/analytics2/channels.vue'
import BotUsers from 'components/analytics2/bot_users.vue'
import { H3 } from 'components/generic'

initAnalytics2App = (element) ->
  isBotcore2 = $(element).data('is-botcore-2')

  routes = [
    name: 'messages'
    label: 'Messages'
    path: '/messages'
    component: Messages
  ,
    name: 'users'
    label: 'Users'
    path: '/users'
    component: Users
  ,
    name: 'handovers'
    label: 'Handover'
    path: '/handovers'
    component: Handovers
  ,
    name: 'livechatSessions'
    label: 'Livechat sessions'
    path: '/livechat_sessions'
    component: LivechatSessions
  ,
    name: 'retention'
    label: 'Retention'
    path: '/retention'
    component: Retention
  ,
    name: 'intents'
    label: 'Intents'
    path: '/intents'
    component: Intents
    additionalProps: ['intentCharts']
  ,
    name: 'channels'
    label: 'Channels'
    path: '/channels'
    component: Channels
  ,
    if isBotcore2
      name: 'userData'
      label: 'User data'
      path: '/user_data'
      component: BotUsers
    else
      null

  ]
    .compact()
    .map (route) -> Object.assign(route, path: '/projects/:projectId/:stageLevel/analytics' + route.path)

  if !Globals.currentUser.may('view_analytics', Globals.project, stageLevel: Globals.stageLevel)
    routes = routes.filter (route) -> route.name == 'livechatSessions'
  routes[0].alias = '/projects/:projectId/:stageLevel/analytics'

  router = createRouter(
    history: createWebHistory()
    routes: routes
  )
  trackRouting(router)

  project = $(element).data('project')
  userSettings = $(element).data('settings') || {}
  settings =
    stageIds: project.stages.filter((stage) -> stage.stage_level == Globals.stageLevel).map((stage) -> stage.uuid)
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    startDate: null
    durationInDays: 0
    resolution: userSettings.resolution || 'day'
    agentTeamId: userSettings.agentTeamId

  createApp(
    data: ->
      isBotcore2: isBotcore2
      project: project
      channels: $(element).data('channels') # later we will fetch this via API
      menuItems: routes.filter (route) -> route.label?
      globals: Globals
      contextParameters: $(element).data('context-parameters')
      intentSignatures: $(element).data('intent-signatures')
      agentTeams: []
      initialSettings: userSettings.rangeSettings || {}
      settings: settings
      filters: (userSettings.filters || []).map (filter) -> new ParameterFilter(filter)
      intentCharts:
        userSettings.intentCharts || {bot: [], user: []}
      data: new AnalyticsData()
      mounted: false
      startedLoadingAt: null
      timeSettingsDisabled: null
      mayUseFilters: Globals.restrictions.analytics_filters
      # for bot user table (only BC2)
      botUsersSettings: $(element).data('bot-users-settings') || {}
      fixParameters: $(element).data('fix-parameters')

    computed:
      currentRoute: ->
        return {} unless @$route.name?
        routes.find (route) => route.name == @$route.name
      additionalProps: ->
        o = {}
        (@currentRoute.additionalProps || []).forEach (key) => o[key] = @[key]
        o
      settingsPreviousRange: ->
        startDate = new Date(@settings.startDate)
        startDate.setDate(startDate.getDate() - @settings.durationInDays)
        Object.assign({}, @settings,
          startDate: startDate.toISODate()
          lastDayUpTo: if @endDate.toISODate() == new Date().toISODate() then new Date().toISOTime() else null
        )
      endDate: ->
        date = new Date(@settings.startDate)
        date.setDate(date.getDate() + @settings.durationInDays - 1)
        date
      labels: ->
        switch @settings.resolution
          when 'hour'
            Array.apply(null, Array(24)).map((_, i) -> ('00' + i).slice(-2))
          when 'day'
            Array.apply(null, Array(@settings.durationInDays)).map (_, i) =>
              date = new Date(@settings.startDate)
              date.setDate(date.getDate() + i)
              date.toISODate()
          when 'week'
            Array.apply(null, Array(
              Math.ceil(Date.differenceInDays(new Date(@settings.startDate).startOfWeek(), @endDate) / 7)
            )).map (_, i) =>
              startDate = new Date(@settings.startDate)
              startDate.setDate(startDate.getDate() + i * 7)
              ('00' + startDate.getWeekNumber()).slice(-2)
          when 'month'
            Array.apply(null, Array(
              Date.differenceInFullMonths(new Date(@settings.startDate), @endDate) + 1
            )).map (_, i) =>
              startDate = new Date(@settings.startDate)
              startDate.setMonth(startDate.getMonth() + i)
              startDate.getMonthAbbreviation()
          else
            []
      intentLists: ->
        bot: @intentCharts.bot.flat()
        user: @intentCharts.user.flat()

    watch:
      $route: ->
        @loadData(reload: false)
        @$nextTick => @timeSettingsDisabled = @$refs.view.timeSettingsDisabled
      intentCharts: ->
        @saveSettings()
      filters: ->
        @convertFiltersToSettings()
        @saveSettings()
        @loadData(reload: true)

    created: ->
      window.breadcrumbs.enableEnvironmentSelector()
      @loadChannels()
      @loadContextParameters()
      @loadIntentSignatures()
      @loadAgentTeams()
      @convertFiltersToSettings()

    mounted: ->
      @mounted = true
      @setHeight()
      window.addEventListener('resize', @setHeight)

    unmounted: ->
      window.removeEventListener('resize', @setHeight)

    methods:
      loadChannels: ->
        return unless @isBotcore2
        PlatformApi2.getChannels().then (channels) =>
          @channels = channels
            .filter (ch) -> ch.environment.toLowerCase() == Globals.stageLevel
            .map (ch) -> {id: ch.id, name: ch.label}
      loadContextParameters: ->
        return unless @isBotcore2
        BotbuilderApi.getContextParameters().then (cps) => @contextParameters = cps
      loadIntentSignatures: ->
        return unless @isBotcore2
        Bot.active(light: false)
          .then (activeBot) =>
            activeBot.config.allModules.forEach (module) =>
              @intentSignatures.bot = @intentSignatures.bot.concat(module.botIntents)
              @intentSignatures.user = @intentSignatures.user.concat(module.userIntents)
            ['user', 'bot'].forEach (side) =>
              @intentSignatures[side] = @intentSignatures[side]
                .unique((intent) -> intent.intentIdentifier)
                .sortByString('intentIdentifier')
      loadAgentTeams: ->
        @agentTeams = await PlatformApi.Analytics.getLivechatTeams()
      convertFiltersToSettings: ->
        channelIds = @filters.filter((filter) -> filter.field == 'channel').map((filter) -> filter.value)
        @settings.filters = @filters.filter (filter) -> !filter.fix
        @settings.channelIds = if channelIds.length > 0 then channelIds else null
        @settings.wasInHandover = @filters.find((filter) -> filter.field == 'wasInHandover')?.value
        @settings.minNumberOfMessagesSent = @filters.find((filter) -> filter.field == 'minNumberOfMessagesSent')?.value
      setHeight: ->
        @$refs.main.style.height = document.documentElement.clientHeight -
          @$refs.main.getBoundingClientRect().top + 'px'
        @$refs.aside.style.height = document.documentElement.clientHeight -
          @$refs.aside.getBoundingClientRect().top + 'px'
      updateSettings: (settings) ->
        Object.assign(@settings, settings)
        @saveSettings()
        @loadData(reload: true)
      saveSettings: ->
        return if !@$refs.timeSettings?
        range = @$refs.timeSettings.selectedRange.id
        PlatformApi.Webapp.saveProjectSettings(
          Globals.projectId,
          Globals.stageLevel,
          analytics:
            filters: @filters
            rangeSettings:
              range: range
              startDate: if range == 'custom' then @settings.startDate else null
              duration: if range == 'custom' then @settings.durationInDays else null
            resolution: @settings.resolution
            intentCharts: @intentCharts
            agentTeamId: @settings.agentTeamId
        )
      loadData: ({reload = false}) ->
        return unless @mounted
        return unless @$route.name?
        if reload
          @startedLoadingAt = Date.now()
        @data.setParams(@settings, @settingsPreviousRange, @intentLists)
        @data.load(route: @$route.name, reload: reload)
      updateIntents: (intentCharts) ->
        @intentCharts = intentCharts
        @data.setParams(@settings, @settingsPreviousRange, @intentLists)
        @data.loadIntentsData(true)
      channelLabel: (id) ->
        (@channels.find((ch) -> ch.id == id) || {name: id}).name

    components:
      FilterSidebar: FilterSidebar
      H3: H3
      Menu: Menu
      TimeSettings: TimeSettings
      UpgradePrompt: UpgradePrompt

    template: '
      <div class="bg-white flex">
        <Menu
          title="Analytics"
          :items="menuItems"
          :params="{projectId: globals.projectId, stageLevel: globals.stageLevel}"
          class="tw-hidden md:flex md:w-64 md:flex-shrink-0 md:fixed md:h-screen"
        />

        <div class="flex-1 flex md:ml-64 md:w-[calc(100%-16rem)]">
          <main ref="main" class="flex-1 relative focus:outline-none px-4 sm:px-6 lg:px-8 py-6 overflow-auto">
            <div class="px-4 sm:px-0 mb-5">
              <h1 class="text-2xl font-semibold text-gray-900 text-left m-0">{{ currentRoute.label }}</h1>
            </div>
            <TimeSettings
              v-if="$route.name != \'userData\'"
              ref="timeSettings"
              :settings="settings"
              :initial-settings="initialSettings"
              :disabled-settings="timeSettingsDisabled"
              @change="updateSettings"
            />
            <router-view v-slot="{ Component }">
              <component ref="view" :is="Component" :data="data" :labels="labels" v-bind="additionalProps" @update-intents="updateIntents"/>
            </router-view>
          </main>

          <aside v-show="$route.name != \'userData\'" ref="aside" class="tw-hidden relative xl:flex xl:flex-col flex-shrink-0 w-80 border-l border-gray-200">
            <div class="absolute inset-0 py-6 px-4">
              <FilterSidebar
                v-if="mayUseFilters"
                v-model="filters"
                :channels="channels"
                :context-parameters="contextParameters"
              />
              <template v-else>
                <H3 class="mb-6">Filter</H3>
                <UpgradePrompt
                  message="Setting filters is not available in your subscription plan."
                  prompt="Upgrade in order to enable this feature."
                />
              </template>
            </div>
          </aside>
        </div>
      </div>
    '
  )
    .use(router)
    .mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('analytics2-app')
      initAnalytics2App(element)
