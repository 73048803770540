
  import ContextUpdate from 'models/context_update.coffee'
  import ParameterFilter from 'models/parameter_filter.coffee'
  import PlatformApi2 from 'models/platform_api2.coffee'
  import WorkflowStep from 'models/workflow_step.coffee'

  import ContextUpdateEditor from 'components/botbuilder/context_update_editor.vue'
  import ParameterFilterEditor from 'components/botbuilder/parameter_filter_editor.vue'
  import SendWhatsappTemplateStepEditor from './send_whatsapp_template_step_editor.vue'
  import UniversalInput from 'components/botbuilder/inputs/universal_input.vue'
  import { ActionMenu, Button, CollapsibleCard, H3, Input, KeyListbox, Label, Switch, Textarea } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: WorkflowStep
      integrations: Array

    emits: ['update:modelValue', 'remove']

    data: ->
      step: @modelValue
      channels: [label: 'select channel']
      actionMenuItems: [
        {icon: XIcon, label: 'Remove', method: => @$emit('remove')}
      ]
      PlusIcon: PlusIcon

    computed:
      integration: ->
        return if @step.type != 'InvokeIntegration'
        @integrations.find (integration) => integration.id == @step.integrationId
      color: ->
        if @step.type == 'InvokeIntegration' then 'MagentaViolet' else 'Skobeloff'
      title: ->
        headline = if @step.type == 'InvokeIntegration' then @integration?.label else @step.label
        "<h3 class='text-lg font-medium leading-6 text-gray-900 text-left my-2'>#{headline} <span class='font-normal text-sm ml-3'>#{@step.key}</span></h3>"

    created: ->
      if @step.type == 'IdentifyOrCreateUser'
        PlatformApi2.getChannels()
          .then (channels) =>
            @channels = @channels.concat(channels)
            @$nextTick =>
              @$refs.channelSelector.setSelectedItem()

    methods:
      update: ->
        @$emit('update:modelValue', @step)
      addCondition: ->
        @step.conditions.push(new ParameterFilter())
      removeCondition: (index) ->
        @step.conditions.splice(index, 1)
        @update()
      addContextUpdate: ->
        @step.updates.push(new ContextUpdate())
      removeContextUpdate: (index) ->
        @step.updates.splice(index, 1)
        @update()
      channelLabel: (channel) ->
        return channel?.label unless channel?.id?
        "#{channel.label} (#{channel.id})"

    components:
      ActionMenu: ActionMenu
      Button: Button
      CollapsibleCard: CollapsibleCard
      ContextUpdateEditor: ContextUpdateEditor
      ParameterFilterEditor: ParameterFilterEditor
      H3: H3
      Input: Input
      KeyListbox: KeyListbox
      Label: Label
      SendWhatsappTemplateStepEditor: SendWhatsappTemplateStepEditor
      Switch: Switch
      Textarea: Textarea
      UniversalInput: UniversalInput
      XIcon: XIcon
