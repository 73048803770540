import BotbuilderApi from 'models/botbuilder_api.coffee'
import ContextParameter from 'models/context_parameter.coffee'
import InternalParameter from 'models/internal_parameter.coffee'
import NLG from 'models/nlg.coffee'
import Priming from 'models/priming.coffee'

export default class Verbalization
  @TYPES = [
      'QueryVerbalization'
      'ConfirmExistingVerbalization'
      'ConfirmUnderstoodVerbalization'
      'RetryVerbalization'
      'SuccessVerbalization'
      'FailVerbalization'
    ]

  @IMPLICATIONS = {}
  # implications may have this form:
  # @IMPLICATIONS =
  #   RetryVerbalization: ['ConfirmUnderstoodVerbalization']

  constructor: (@contextInterface, data = {}) ->
    @type = data.type
    @isActive = if data.isActive? then data.isActive else false
    @nlg = new NLG(@, data.nlg)
    @primings = (data.primings || []).map (primingData) => new Priming(@, primingData)
    switch @type
      when 'RetryVerbalization'
        @numberOfRetries = if data.numberOfRetries? then data.numberOfRetries else 2
      when 'ConfirmUnderstoodVerbalization'
        @onlyIfNotAllValidate = if data.onlyIfNotAllValidate? then data.onlyIfNotAllValidate else false

  searchVariables: (query, {types, limit} = {}) =>
    BotbuilderApi.search(@botId, query, types: types, context: @type, limit: limit)
      .then (data) =>
        data
          .sort (a, b) -> b.score - a.score
          .map (matchData) =>
            switch matchData.resourceType
              when 'ContextParameter'
                new ContextParameter(matchData.entity)
              when 'InternalParameter'
                new InternalParameter(matchData.entity)

  loadVariables: ->
    @_variablesLoading = true
    @searchVariables('', types: ['InternalParameter'], context: @type, limit: 999)
      .then (@_variables) => @_variables
      .finally => @_variablesLoading = false

  getVariables: ->
    if !@_variables? && !@_variablesLoading
      @_loadingVariablesPromise = @loadVariables()
    else if @_variablesLoading
      @_loadingVariablesPromise
    else
      Promise.resolve(@_variables)

  # only update attributes that may be updated by the BE
  partialUpdate: (data = {}) ->
    @primings = (data.primings || []).map (primingData) => new Priming(@, primingData)

  Object.defineProperties @prototype,
    botId:
      get: ->
        @contextInterface.botId
    parameterKey:
      get: ->
        @contextInterface.parameterKey
    export:
      get: ->
        obj =
          type: @type
          isActive: @isActive
          nlg: @nlg.export
          primings: @primings.map (priming) => priming.export
        switch @type
          when 'RetryVerbalization'
            obj.numberOfRetries = @numberOfRetries
          when 'ConfirmUnderstoodVerbalization'
            obj.onlyIfNotAllValidate = @onlyIfNotAllValidate
        obj
