
  import Bot from 'models/bot.coffee'
  import ConfigHint from 'models/config_hint.coffee'
  import { Badge, DocumentationLink, MultiselectDropdown, PageHeader } from 'components/generic'
  import { ExternalLinkIcon } from '@heroicons/vue/outline'

  export default
    props:
      bot: Bot
      userSettings: Object

    data: ->
      hints: []
      categories: []
      loading: false
      severityLevels: ConfigHint.SEVERITY_LEVELS.map (sl) -> {key: sl}
      resourceTypes: ConfigHint.RESOURCE_TYPES
        .filter((rt) -> !['Workflow', 'Integration'].includes(rt))
        .map (rt) -> {key: rt}
      colorPerSeverity:
        fatal: 'red'
        error: 'red'
        warn: 'yellow'
        info: 'blue'
        suggestion: 'green'

    computed:
      categoriesForSelect: ->
        @categories.map (cat) -> key: cat
      dialogModules: ->
        @$root.currentBot.config.allModules.sortByString('label')
      filters: ->
        @userSettings?.hintsAndErrors?.filters

    watch:
      categories: ->
        oldLength = @filters.categories.length
        @filters.categories = @filters.categories.filter (filterCat) => @categories.includes(filterCat.key)
        @$emit('settings-changed') if oldLength != @filters.categories.length

    created: ->
      @initSettings()
      @loadHints(settingsChanged: false)

    methods:
      initSettings: ->
        @userSettings.hintsAndErrors ||= {}
        @userSettings.hintsAndErrors.filters ||= {}
        @userSettings.hintsAndErrors.filters.severities ||= []
        @userSettings.hintsAndErrors.filters.resourceTypes ||= []
        @userSettings.hintsAndErrors.filters.modules ||= []
        @userSettings.hintsAndErrors.filters.categories ||= []
      loadHints: ({settingsChanged} = {settingsChanged: true}) ->
        @loading = true
        filterParams = {}
        @$emit('settings-changed') if settingsChanged
        if @filters.severities.length > 0
          filterParams.severities = @filters.severities.map((s) -> s.key).join(',')
        if @filters.resourceTypes.length > 0
          filterParams.resourceTypes = @filters.resourceTypes.map((r) -> r.key).join(',')
        if @filters.modules.length > 0
          filterParams.moduleKeys = @filters.modules.map((m) -> m.key).join(',')
        if @filters.categories.length > 0
          filterParams.categories = @filters.categories.map((c) -> c.key).join(',')
        @$root.currentBot.getHints(Object.assign(filterParams, limit: 30))
          .then (hints) =>
            @hints = hints
            @categories = Array.unique(@categories.concat(@hints.map (err) -> err.category)).sort()
          .finally =>
            @loading = false
      routerTarget: (hint) ->
        switch hint.coordinate.resourceType
          when 'BotIntent', 'UserIntent', 'TriggerIntent', 'DialogAction'
            name: 'module'
            params: {moduleKey: hint.coordinate.moduleKey}
            hash: "##{hint.coordinate.identifier}"
          when 'DialogModule'
            name: 'module'
            params: {moduleKey: hint.coordinate.identifier}
          when 'ContextParameter'
            name: 'contextParameter'
            params: {parameterKey: hint.coordinate.identifier}
          when 'Hook'
            name: 'hook'
            params: {hookKey: hint.coordinate.identifier}
          else
            {}

    components:
      Badge: Badge
      DocumentationLink: DocumentationLink
      ExternalLinkIcon: ExternalLinkIcon
      MultiselectDropdown: MultiselectDropdown
      PageHeader: PageHeader
