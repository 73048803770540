
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import KnowledgeSource from 'models/knowledge_source.coffee'

  import SourceCreatorModal from './source_creator_modal.vue'
  import StatusIcon from './status_icon.vue'
  import { BookOpenIcon, PlusIcon } from '@heroicons/vue/outline'
  import { Button, DocumentationLink, H3, PageHeader } from 'components/generic'

  export default
    data: ->
      sources: []
      pollingTimeouts: {}
      PlusIcon: PlusIcon

    created: ->
      @sources = await KnowledgeSource.list()
      @sources.forEach (source) => @updateAndPoll(source)

    unmounted: ->
      Object.values(@pollingTimeouts).forEach (timeout) -> clearTimeout(timeout)

    methods:
      goTo: (source) ->
        @$router.push(name: 'knowledgeSource', params: {sourceId: source.id})
      openCreator: ->
        @$refs.creator.open()
      updateAndPoll: (source, data) ->
        source.updateStatusModel(data)
        return if !source.id
        @pollingTimeouts[source.id] = setTimeout((=> @poll(source)), 3000) if source.inProgress
      poll: (source) ->
        return if !source.id
        BotbuilderApi.getKnowledgeSourceStatus(source.id)
          .then (data) => @updateAndPoll(source, data)

    components:
      BookOpenIcon: BookOpenIcon
      Button: Button
      DocumentationLink: DocumentationLink
      H3: H3
      PageHeader: PageHeader
      PlusIcon: PlusIcon
      SourceCreatorModal: SourceCreatorModal
      StatusIcon: StatusIcon
