
  import TailwindConfig from '../../../../tailwind.config.js'
  import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
  import StatusOverlay from './status_overlay.vue'
  import { ActionMenu, H3 } from 'components/generic'
  import { DownloadIcon } from '@heroicons/vue/outline'

  export default
    props:
      title: String
      subtitle: String
      datasets: Array
      labels: Array
      statusObject:
        type: Object
        default: {}
      type:
        type: String
        default: 'line'
      additionalActions:
        type: Array
        default: []
      displayTotals:
        type: Boolean
        default: false
      percentages:
        type: Boolean
        default: false
      hideLegend:
        type: Boolean
        default: false

    data: ->
      mounted: false
      display: true
      actionMenuItems: [
        {icon: DownloadIcon, label: 'Download chart data', method: @downloadData},
        {icon: DownloadIcon, label: 'Download chart as image', method: @downloadImage},
      ].concat(@additionalActions)
      chartOptions:
        if @type == 'bar'
          plugins:
            legend: false
            tooltip:
              enabled: false
              external: @setChartTooltip
        else
          scales:
            y:
              stacked: @type == 'area'
          plugins:
            legend:
              display: !@hideLegend
              onHover: @legendTooltip
              labels:
                generateLabels: (chart) =>
                  chart.data.datasets.map (dataset, datasetIndex) =>
                    meta = chart.getDatasetMeta(datasetIndex)
                    text: if @displayTotals then "#{dataset.label} (total: #{dataset.data.reduce(((a, b) -> a + b), 0)})" else dataset.label
                    fontColor: if meta.hidden then TailwindConfig.theme.colors.gray[400] else TailwindConfig.theme.colors.gray[700]
                    fillStyle: dataset.backgroundColor
                    lineWidth: dataset.borderWidth
                    strokeStyle: dataset.borderColor
                    datasetIndex: datasetIndex # extra data used for toggling the datasets
            tooltip:
              mode: 'index'
              axis: 'xy'
              intersect: false
              enabled: false
              external: @setChartTooltip
      previousChartData: {}
      legendTooltipContent: ''
      legendTooltipStyle: {}
      chartTooltip:
        style: {}
        title: ''
        lines: []

    computed:
      chartData: ->
        colors = switch @type
          when 'line'
            colorsOnce = [
              {r: 118, g: 180, b: 0}, # green
              {r: 0, g: 158, b: 226}, # blue
              {r: 246, g: 81, b: 29}, # red
              {r: 255, g: 180, b: 0}, # yellow
              {r: 97, g: 117, b: 19}, # avocado
              {r: 6, g: 115, b: 127}, # skobeloff
              {r: 85, g: 65, b: 145}, # magenta violet
              {r: 178, g: 30, b: 30}, # cornell red
            ]
            Array(5).fill(colorsOnce).flat()
          when 'area'
            [
              {r: 85, g: 65, b: 145},
              {r: 5, g: 172, b: 241},
              {r: 179, g: 230, b: 250},
            ]
          when 'bar'
            [
              {r: 54, g: 162, b: 235},
            ]
        backgrounds = @datasets.map (dataset, i) =>
          return 'rgba(0, 0, 0, 0)' if !@mounted || @type == 'line'
          return "rgba(#{colors[i].r}, #{colors[i].g}, #{colors[i].b}, 0.2)" if @type == 'bar'
          canvas = @$refs.chart.$el
          gradient = canvas.getContext('2d').createLinearGradient(0, 0, 0, 280)
          gradient.addColorStop(0, "rgba(#{colors[i].r}, #{colors[i].g}, #{colors[i].b}, 0.8)")
          gradient.addColorStop(0.5, "rgba(#{colors[i].r}, #{colors[i].g}, #{colors[i].b}, 0.4)")
          gradient.addColorStop(1, "rgba(#{colors[i].r}, #{colors[i].g}, #{colors[i].b}, 0.8)")
          gradient

        labels: @labels
        datasets: @datasets.map (dataset, i) =>
          Object.assign(
            backgroundColor: backgrounds[i]
            borderColor: "rgba(#{colors[i].r}, #{colors[i].g}, #{colors[i].b}, #{if @type == 'bar' then 1 else 0.9})"
            pointBorderColor: "rgba(#{colors[i].r}, #{colors[i].g}, #{colors[i].b}, 1)"
            pointBorderWidth: 2
            pointRadius: 2
            borderWidth: 3
            tension: 0.3
            fill: true
          , dataset
          )

    watch:
      chartData: ->
        return if !@mounted
        # this function is triggered even if @chartData actually doesn't change, so I use this trick to avoid useless re-rendering
        return if ObjectProcessor.equal(@chartData, @previousChartData)
        @previousChartData = ObjectProcessor.clone(@chartData, removeTransientProperties: false)
        # for some reason, chart.update() doesn't work, so we must do it like this:
        @display = false
        @$nextTick =>
          @display = true

    mounted: ->
      @mounted = true

    methods:
      downloadData: ->
        chart = @$refs.chart
        rows = [[''].concat(chart.data.datasets.map (dataset) => @quoted(dataset.label))]
        chart.data.labels.forEach (label, index) =>
          rows.push(
            [label].concat(chart.data.datasets.map (dataset) -> dataset.data[index])
          )
        csvString = rows.map((row) -> row.join(',')).join("\n")
        @download('data:text/csv;charset=utf-8,' + encodeURIComponent(csvString), "#{@title}.csv")
      downloadImage: ->
        chart = @$refs.chart
        pngString = chart.chartJSState.chart.toBase64Image()
        @download(pngString, "#{@title}.png")
      download: (fileString, fileName) ->
        a = document.createElement('a')
        a.href = fileString
        a.download = fileName
        a.click()
        a.remove()
      quoted: (string) ->
        return '' if !string?
        '"' + string.replace(/"/g, '""') + '"'
      percentLabel: (value) ->
        return '' if !value?
        (value * 100).toFixed(2) + '%'
      legendTooltip: (e, legendItem, legend) ->
        index = legendItem.datasetIndex
        return if !legend.chart.data.datasets[index].legendTooltip
        @legendTooltipContent = legend.chart.data.datasets[index].legendTooltip
        @legendTooltipStyle = {display: 'block', left: "#{e.x}px", top: "#{e.y}px"}
      setChartTooltip: ({chart, tooltip}) ->
        if tooltip.opacity == 0
          @chartTooltip.style.opacity = 0
          return

        if tooltip.body
          total = tooltip.dataPoints.map((dataPoint) -> dataPoint.raw).sum()
          @chartTooltip.title = (tooltip.title || []).join("\n")
          if @type == 'area'
            @chartTooltip.beforeBody = "total: #{total}"
          @chartTooltip.lines = tooltip.dataPoints
            .map (dataPoint) =>
              dataset = dataPoint.dataset
              rawNumber = dataPoint.raw
              text = if @type == 'area' && total > 0
                percentLabel = @percentLabel(rawNumber / total)
                "#{dataset.label}: #{rawNumber} (#{percentLabel})"
              else if @percentages
                "#{dataset.label}: #{rawNumber}%"
              else
                if dataset.label? then "#{dataset.label}: #{rawNumber}" else rawNumber
              text: text
              colorStyle:
                backgroundColor: dataset.borderColor
            .reverse()

        @$nextTick =>
          # set tooltip position, avoid clipping
          tooltipWidth = @$refs.chartTooltip.offsetWidth
          wrapperWidth = @$refs.chartWrapper.offsetWidth
          @chartTooltip.style =
            opacity: 1
            left: Math.min(chart.canvas.offsetLeft + tooltip.caretX, wrapperWidth - tooltipWidth) + 'px'
            top: chart.canvas.offsetTop + tooltip.caretY + 'px'

    components:
      ActionMenu: ActionMenu
      H3: H3
      StatusOverlay: StatusOverlay
      Vue3ChartJs: Vue3ChartJs
