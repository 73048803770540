
  import Integration from 'models/integration.coffee'
  import { Input, Listbox, Panel } from 'components/generic'

  export default
    props:
      modelValue: Integration

    emits: ['update:modelValue', 'close']

    data: ->
      integrationTypes = Object.entries(Integration.TYPES).map ([key, config]) -> {id: key, name: config.label}

      integration: @modelValue.clone()
      integrationTypes: integrationTypes
      integrationType: integrationTypes.find (it) => it.id == @modelValue.type

    watch:
      integrationType: ->
        return if !@integrationType?
        @integration.setType(@integrationType.id)

    methods:
      save: ->
        @$emit('close')
        if @integration.pristine
          @integration.save()
            .then => @$router.push(name: 'integration', params: {integrationId: @integration.id})
        else
          @$emit('update:modelValue', @integration)
      cancel: ->
        if @integration.pristine
          @$router.push(name: 'integrationList')
        else
          @$emit('close')

    components:
      Input: Input
      Listbox: Listbox
      Panel: Panel
