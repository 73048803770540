import { createApp } from 'vue_shims'
import GuidGenerator from 'models/guid_generator.coffee'
import PlatformApi2 from 'models/platform_api2.coffee'
import WebChannel from 'models/web_channel.coffee'
import { alert } from 'helpers'

import { Button, H3 } from 'components/generic'
import { ClipboardIcon } from '@heroicons/vue/outline'

initWidgetTestApp = (element) ->
  createApp(
    data: ->
      elementData = JSON.parse(element.getAttribute('data'))

      channel: new WebChannel(nativeId: elementData.channel_key)
      projectId: elementData.project_id
      userId: GuidGenerator.newGuid()

    computed:
      projectHomePath: ->
        Routes.project_path(@projectId, 'dev')

    mounted: ->
      config =
        apiKey: @channel.nativeId
        apiServer: @channel.widgetServer
        displayMode: 'block'
        pollingInterval: 3
      user = {userId: @userId}
      Mercury.initChatWidget(@$refs.widget, config, user)
      @setHeight()

    methods:
      setHeight: ->
        setTimeout =>
          if document.querySelector('#mercury-widget')?
            document.querySelector('#mercury-widget').style.height = '100%'
            document.querySelector('#chat-widget').style.height = '100%'
            document.querySelector('#chat-widget').style.maxHeight = '100%'
          else
            @setHeight()
        , 100
      copySnippet: (e) ->
        navigator.clipboard.writeText(@channel.snippet)
          .then =>
            e.target.blur()
            await alert('Copied snippet to clipboard')
      copyUrl: (e) ->
        navigator.clipboard.writeText(window.location.href.split('?')[0])
          .then =>
            e.target.blur()
            await alert('Copied URL to clipboard')

    components:
      Button: Button
      ClipboardIcon: ClipboardIcon
      H3: H3

    template: '
      <div v-if="channel" v-html="channel.styleLinkTag"></div>

      <div class="flex flex-col items-center">
        <h2 class="mt-6 mb-3 text-center text-3xl font-extrabold text-gray-800">
          Test your bot
        </h2>

        <p class="mb-8 text-gray-600">Ask your bot about the knowledge source you have added!</p>

        <div class="mb-10 shrink-0" style="height: 600px; width: 400px;">
          <div ref="widget" class="h-full"></div>
        </div>

        <Button v-if="projectId" :href="projectHomePath" type="primary" class="-mt-2 mb-8">
          Go to project dashboard
        </Button>

        <div class="w-96 bg-gray-50 shadow sm:rounded-lg px-6 py-4 space-y-4">
          <H3>Share this page</H3>
          <Button @click="copyUrl" size="sm">
            <ClipboardIcon class="-ml-0.5 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true"/>
            copy url
          </Button>
        </div>

      </div>
    '
  ).mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('widget-test-app')
      initWidgetTestApp(element)
