import BotbuilderApi from 'models/botbuilder_api.coffee'
import ContextInitiative from 'models/context_initiative.coffee'

export default class ContextInterface
  constructor: (@botConfig, data = {}) ->
    if data.contextParameter?
      @contextParameter = data.contextParameter
      @parameterKey = data.contextParameter.key
    else
      @parameterKey = data.parameterKey
    @update(data)

  update: (data) ->
    data ||= {}
    @initiative = new ContextInitiative(@, data.initiative)
    @defaultValue = data.defaultValue if data.defaultValue?
    this

  # only update attributes that may be updated by the BE
  partialUpdate: (data = {}) ->
    @initiative.partialUpdate(data.initiative)

  clone: ->
    new ContextInterface(@botConfig, @export)

  load: ->
    BotbuilderApi.getContextInterface(@botConfig.id, @key).then (data) =>
      @update(data)

  save: ->
    BotbuilderApi.updateContextInterface(@)
      .then (data) => @partialUpdate(data)

  Object.defineProperties @prototype,
    export:
      get: ->
        parameterKey: @parameterKey
        initiative: @initiative.export
        defaultValue: @defaultValue
    botId:
      get: -> @botConfig.id
    key:
      get: ->
        @parameterKey
    label:
      get: ->
        @contextParameter?.label
    isUserParameter:
      get: ->
        @parameterKey.startsWith('user.')
    understoodValueRef:
      get: ->
        'internal.initiative.understoodValue'
