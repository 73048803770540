
  import GuidGenerator from 'models/guid_generator.coffee'

  import Badge from './badge.vue'
  import Input from './input.vue'
  import Label from './label.vue'
  import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
  } from '@headlessui/vue'
  import { TerminalIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: [Object, String]
      label: String
      placeholder: String
      items: Array
      filterFunction: Function
      searchFunction: Function
      initialQuery: String
      labelFunction:
        type: Function
        default: (item) -> item
      inputLabelFunction:
        type: Function
        default: null
      invalidFunction:
        type: Function
        default: (item) -> false
      keyAttribute:
        type: String
        default: 'key'
      badgeAttribute:
        type: String
        default: null
      disabled:
        type: Boolean
        default: false
      align:
        type: String
        default: 'left'
      displayInfo:
        type: Boolean
        default: false

    emits: ['update:modelValue', 'queryChange', 'cancel']

    data: ->
      id: GuidGenerator.newGuid()
      value: @modelValue
      query: ''
      filteredItems: @items
      optionRefs: {}
      popupStyle: {}
      activeOption: null
      displayPopup: false
      blurTimeout: null
      debounceTimeout: null

    computed:
      inputFieldLabelFunction: ->
        @inputLabelFunction || @labelFunction
      activeOptionKey: ->
        @activeOption?[@keyAttribute]

    watch:
      query: ->
        @$emit('queryChange', @query)
        @filterItems()
      modelValue: ->
        @value = @modelValue
      items: ->
        @filterItems()

    mounted: ->
      @$nextTick =>
        @$refs.input.$el.value = @initialQuery if @initialQuery

    beforeUpdate: ->
      @optionRefs = {}

    methods:
      setActiveOption: (option) ->
        return if option == @activeOption
        @activeOption = option
        return unless @showPopup()
        @$nextTick => @setPopupPosition()
      emitUpdate: ->
        clearTimeout(@blurTimeout)
        @$emit('update:modelValue', @value)
      emitDelayedCancel: ->
        @blurTimeout = setTimeout =>
          @$emit('cancel')
        , 200
      open: ->
        @$refs.button.$el.click()
      focus: ->
        @$refs.input.$el.focus()
      filterItems: ->
        if !@query
          @filteredItems = @items
          return
        if @filterFunction?
          @filteredItems = @items.filter (item) => @filterFunction(item, @query)
        else if @searchFunction?
          clearTimeout(@debounceTimeout)
          @debounceTimeout = setTimeout(=>
            @searchFunction(@query).then (items) => @filteredItems = items
          , 200
          )
      isSeparator: (item) ->
        !item?[@keyAttribute] && @labelFunction(item) == '-----'
      showBadgeAttribute: (item) ->
        @badgeAttribute && item?[@badgeAttribute]
      dotColor: (item) ->
        switch item?.type
          when 'BotIntent' then 'text-MercuryGreen-600'
          when 'DialogAction' then 'text-MercuryYellow-600'
          when 'UserIntent' then 'text-MercuryBlue-600'
      setOptionRef: (key, el) ->
        @optionRefs[key] = el if el?
      setPopupPosition: ->
        return unless @showPopup()
        top = @optionRefs[@activeOptionKey].$el.getBoundingClientRect().y
        left = @$el.getBoundingClientRect().x
        @popupStyle =
          top: "#{top}px"
          left: "#{left}px"
      showPopup: ->
        return @displayPopup = false unless @displayInfo
        return @displayPopup = false unless @activeOptionKey?
        return @displayPopup = false unless @optionRefs[@activeOptionKey]?.$el?
        rect = @optionRefs[@activeOptionKey].$el.getBoundingClientRect()
        containerRect = @$refs.scrollContainer.$el.getBoundingClientRect()
        @displayPopup = (containerRect.top <= rect.y < containerRect.bottom)

    components:
      Badge: Badge
      Combobox: Combobox
      ComboboxButton: ComboboxButton
      ComboboxInput: ComboboxInput
      ComboboxOptions: ComboboxOptions
      ComboboxOption: ComboboxOption
      Input: Input
      Label: Label,
      TerminalIcon: TerminalIcon,
