
  import ContextParameter from 'models/context_parameter.coffee'
  import ContextInterface from 'models/context_interface.coffee'
  import Verbalization from 'models/verbalization.coffee'

  import ContextInitiativeAdvancedEditor from './context_initiative_advanced_editor.vue'
  import LanguageSelector from 'components/botbuilder/language_selector.vue'
  import OperationalHooksEditor from './operational_hooks_editor.vue'
  import UtteranceCollectionEditor from 'components/botbuilder/utterances/utterance_collection_editor.vue'
  import VerbalizationPanel from './verbalization_panel.vue'
  import VerbalizationEditor from './verbalization_editor.vue'
  import DataTypeDependentInput from '../inputs/data_type_dependent_input.vue'
  import { ActionMenu, BackLink, H3, Label, Listbox, Switch } from 'components/generic'
  import { PencilIcon } from '@heroicons/vue/outline'

  export default
    props:
      contextInterface: ContextInterface
      contextParameter: ContextParameter

    emits: ['edit']

    data: ->
      debounceTimeout: null
      watchersOff: false
      actionMenuItems: [
        {icon: PencilIcon, label: 'Edit', method: => @$emit('edit')}
      ]
      useDefaultValue: false
      currentVerbalization: null
      verbalizationConfig:
        ConfirmExistingVerbalization: {label: 'Confirm existing', description: 'If there is already a value stored in this context when the initiative is triggered, you can use this message to let the user confirm the existing value. When the user denies, Query will be played.'}
        QueryVerbalization: {label: 'Query', description: 'If there is no value stored in this context or Confirm existing is objected by the user, this message will ask the user for a value.'}
        RetryVerbalization: {label: 'Retry', description: 'If Confirm understood is objected by the user or a provided value does not validate, this message lets you rephrase your query.'}
        ConfirmUnderstoodVerbalization: {label: 'Confirm understood', description: 'If you have used Query or Retry to ask the user for a value, this message lets you ask for a confirmation of the understood value. When the user denies, Retry will be played.'}
        SuccessVerbalization: {label: 'Success', description: 'If the initiative ends with a valid value, this lets you send a success message.'}
        FailVerbalization: {label: 'Fail', description: 'If the initiative ends without a valid value, this lets you send a fail message.'}

    computed:
      verbalizations: ->
        Verbalization.TYPES.map (type) => @contextInterface.initiative[type.decapitalize()]
      queryVerbalization: ->
        @verbalizations.find (verbalization) -> verbalization.type == 'QueryVerbalization'
      fixedSlotDefinition: ->
        return if @contextInterface.initiative.slotDefinitions?
        {slotRole: @contextParameter.key, valueType: @contextParameter.valueType}

    watch:
      useDefaultValue: ->
        return if @watchersOff
        if @useDefaultValue
          @verbalizations.forEach (verbalization) -> verbalization.isActive = false
          @contextInterface.defaultValue = if @contextParameter.valueType == 'bool' then 'false' else ''
          @$nextTick =>
            @$refs.defaultValueInput.focus()
        else
          @queryVerbalization.isActive = true
          @contextInterface.defaultValue = null
        @updateWithDebounce()
      contextInterface: (newValue, oldValue) ->
        return if @watchersOff
        if newValue? && !oldValue?
          @setHeight()
        @setStuffFromContextInterface()

    created: ->
      @setStuffFromContextInterface()

    mounted: ->
      @setHeight() if @contextInterface?
      window.addEventListener('resize', @setHeight)

    unmounted: ->
      window.removeEventListener('resize', @setHeight)

    methods:
      setStuffFromContextInterface: ->
        return if !@contextInterface?
        @withoutWatching =>
          @useDefaultValue = @contextInterface.defaultValue?
      active: (verbalization) ->
        return false if !@currentVerbalization?
        @currentVerbalization == verbalization
      edit: (verbalization) ->
        if @currentVerbalization == verbalization
          @currentVerbalization = null
        else
          # We need to do this trick in order to trigger immediate saving of message texts.
          # Otherwise the old verbalization's texts might show up in the new verbalization.
          @currentVerbalization = null
          @$nextTick =>
            @currentVerbalization = verbalization
      updateWithDebounce: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(@update, 1000)
      update: ->
        @contextInterface.save()
      activate: (verbalizationTypes) ->
        verbalizationTypes.forEach (type) =>
          @contextInterface.initiative[type.decapitalize()].isActive = true
        @useDefaultValue = false
        @update()
      deactivate: (verbalizationTypes) ->
        verbalizationTypes.forEach (type) =>
          @contextInterface.initiative[type.decapitalize()].isActive = false
        if !(@contextInterface.isUserParameter || @contextInterface.initiative.queryVerbalization.isActive)
          @useDefaultValue = true
        @update()
      withoutWatching: (callback) ->
        @watchersOff = true
        callback()
        @watchersOff = false
      setHeight: ->
        @$nextTick =>
          top = @$refs.grayPanel.getBoundingClientRect().top
          @$refs.grayPanel.style.height = window.innerHeight - top + 'px'

    components:
      ActionMenu: ActionMenu
      BackLink: BackLink
      ContextInitiativeAdvancedEditor: ContextInitiativeAdvancedEditor
      DataTypeDependentInput: DataTypeDependentInput
      H3: H3
      Label: Label
      LanguageSelector: LanguageSelector
      Listbox: Listbox
      OperationalHooksEditor: OperationalHooksEditor
      PencilIcon: PencilIcon
      Switch: Switch
      UtteranceCollectionEditor: UtteranceCollectionEditor
      VerbalizationEditor: VerbalizationEditor
      VerbalizationPanel: VerbalizationPanel
