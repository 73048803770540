
  import Condition from 'models/condition.coffee'
  import ContextInitiative from 'models/context_initiative.coffee'
  import ContextInterface from 'models/context_interface.coffee'

  import ConditionEditor from '../condition_editor.vue'
  import { Combobox, KeyListbox, Label, Switch } from 'components/generic'
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
  import { ChevronDownIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: ContextInitiative
      contextInterface: ContextInterface

    data: ->
      initiative: @modelValue
      relevantIf: null
      mandatory: null
      positiveConfirmationHook: @$root.currentBot.config.hooks.find (hook) =>
        hook.key == @modelValue.positiveConfirmationHookKey
      negativeConfirmationHook: @$root.currentBot.config.hooks.find (hook) =>
        hook.key == @modelValue.negativeConfirmationHookKey
      conditionSelection: [
        {key: 'AlwaysTrue', label: 'Always'}
        {key: 'AlwaysFalse', label: 'Never'}
        {key: 'If', label: 'If'}
      ]
      interpretationModes: [
        {key: 'local', label: 'Local'}
        {key: 'competing', label: 'Competing'}
      ]

    watch:
      modelValue: ->
        @initiative = @modelValue
        @setCopies()

    created: ->
      @setCopies()

    methods:
      setCopies: ->
        @relevantIf = if ['AlwaysTrue', 'AlwaysFalse'].includes(@initiative.relevantIf.type)
          @initiative.relevantIf.type
        else
          'If'
        @mandatory = if ['AlwaysTrue', 'AlwaysFalse'].includes(@initiative.mandatory.type )
          @initiative.mandatory.type
        else
          'If'
      update: ->
        @$emit('update:modelValue', @initiative)
      updateRelevantIf: ->
        @initiative.relevantIf = if @relevantIf == 'If' then new Condition() else new Condition(type: @relevantIf)
        @update()
      updateMandatory: ->
        @initiative.mandatory = if @mandatory == 'If' then new Condition() else new Condition(type: @mandatory)
        @update()
      hookLabel: (hook) ->
        hook?.label
      searchHooks: (query) ->
        @$root.currentBot.config.searchHooks(query)
      updateHooks: ->
        @initiative.positiveConfirmationHookKey = @positiveConfirmationHook.key if @positiveConfirmationHook?.key?
        @initiative.negativeConfirmationHookKey = @negativeConfirmationHook.key if @negativeConfirmationHook?.key?
        @update()

    components:
      ChevronDownIcon: ChevronDownIcon
      Combobox: Combobox
      ConditionEditor: ConditionEditor
      Disclosure: Disclosure
      DisclosureButton: DisclosureButton
      DisclosurePanel: DisclosurePanel
      KeyListbox: KeyListbox
      Label: Label
      Switch: Switch
