import { createApp } from 'vue_shims'
import Analytics from 'analytics.coffee'
import Bot from 'models/bot.coffee'
import BotbuilderApi from 'models/botbuilder_api.coffee'
import InboxMessage from 'models/inbox_message.coffee'
import PlatformApi from 'models/platform_api.coffee'
import Project from 'models/project.coffee'
import WebChannel from 'models/web_channel.coffee'

import Logo from 'images/logo_MercuryAI_bg_black.svg'
import SourceCreator from 'components/botbuilder/knowledge/source_creator.vue'
import WaitingAnimation from 'components/project_creation/waiting_animation.vue'
import { Alert, Button, Input, KeyListbox, Spinner } from 'components/generic'

initProjectCreationApp = (element) ->
  createApp(
    data: ->
      step: 'project'
      label: ''
      projectLanguage: null
      languagesForSelector: [label: '🦜 Choose a language for your bot'].concat(Project.AVAILABLE_LANGUAGES)
      projectCreated: false
      bot: null
      source: null
      sourceCreatorMounted: false
      sourceCountdown: null
      sourceErrorMessage: null
      question: null
      answer: null
      channel: null
      Logo: Logo

    computed:
      sourceCreationDisabled: ->
        return true unless @projectCreated
        return true unless @sourceCreatorMounted
        @$refs.sourceCreator.files.length == 0 && (
          @$refs.sourceCreator.newSource.allowedBaseUrls.length == 0 ||
            @$refs.sourceCreator.newSource.allowedBaseUrls[0].length == 0
        )

    watch:
      step: ->
        pageName = switch @step
          when 'knowledge' then 'create_first_knowledge_source'
          when 'buildingSource' then 'building_first_knowledge_source'
        @track(window.location.href + '/' + @step, pageName)
        if @step == 'knowledge'
          @$nextTick => @sourceCreatorMounted = true

    mounted: ->
      @$refs.labelInput.focus()

    methods:
      track: (url, pageName) ->
        Analytics.track('Page opened', url: url, page_name: pageName)
      createProject: ->
        @step = 'knowledge'
        PlatformApi.Webapp.createBc2Project(label: @label, language: @projectLanguage, user_language: @projectLanguage)
          .then (projectData) =>
            @projectCreated = true
            # set needed Globals data for BotbuilderApi
            window.Globals.project = projectData
            # create initial bot
            @bot = new Bot(label: @label)
            @bot.createFromTemplate('EMPTY')
      createKnowledgeSource: ->
        if @sourceErrorMessage?
          @sourceErrorMessage = null
          await @source.delete()
        @step = 'buildingSource'
        @$refs.sourceCreator.create()
          .then (newSource) =>
            @source = newSource
            @sourceCountdown = 90
            @countDown()
            @updateAndPoll()
      countDown: ->
        @countdownTimeout = setTimeout(=>
          @sourceCountdown -= 1
          if @sourceCountdown == 0
            @finishSource()
          else
            @countDown()
        , 1000)
      updateAndPoll: (data) ->
        @source.updateStatusModel(data)
        if @source.inProgress
          @pollingTimeout = setTimeout(@poll, 5000)
        else
          @finishSource()
      poll: ->
        BotbuilderApi.getKnowledgeSourceStatus(@source.id)
          .then (data) => @updateAndPoll(data)
      finishSource: ->
        clearTimeout(@countdownTimeout)
        clearTimeout(@pollingTimeout)
        if @source.syncStatus == 'FAILED' || @source.totalDocuments == 0
          @sourceErrorMessage = 'Ups, the website you entered resists to be crawled. Please try uploading documents or enter a different URL.'
          @step = 'knowledge'
        else
          @createChannel()
      createChannel: ->
        @channel = new WebChannel(
          type: 'WEB'
          label: @label
          environment: 'DEV'
          defaultLanguage: @projectLanguage
        )
        @channel.save()
          .then => @channel.connect()
          .then =>
            @channel.saveDefaultConfig()
            @channel.uploadStyle()
          .then => Turbolinks.visit(Routes.widget_path(@channel.nativeId, project_id: Globals.project.id))

    components:
      Alert: Alert
      Button: Button
      Input: Input
      KeyListbox: KeyListbox
      SourceCreator: SourceCreator
      Spinner: Spinner
      WaitingAnimation: WaitingAnimation

    template: '
      <div class="h-screen min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-lg">

          <template v-if="step == \'project\'">
            <div class="mb-8">
              <img :src="Logo" alt="Mercury.ai" class="mx-auto h-12 w-auto"/>
              <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-800">
                Create your bot
              </h2>
            </div>

            <div class="bg-white pt-6 pb-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-6">
              <Input v-model="label" placeholder="👋 Give your bot a name" ref="labelInput"/>
              <KeyListbox v-model="projectLanguage" :items="languagesForSelector"/>
              <Button @click="createProject" :disabled="!label || !projectLanguage" type="primary" class="w-full">
                Next
              </Button>
            </div>
          </template>

          <template v-if="step == \'knowledge\'">
            <div class="mb-8">
              <img :src="Logo" alt="Mercury.ai" class="mx-auto h-12 w-auto"/>
              <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-800">
                Add knowledge
              </h2>
            </div>
            <div class="bg-white pt-6 pb-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-6">
              <Alert v-if="sourceErrorMessage">{{ sourceErrorMessage }}</Alert>
              <SourceCreator ref="sourceCreator" :source-label="label"/>
              <Button @click="createKnowledgeSource" :disabled="sourceCreationDisabled" type="primary" class="w-full">
                Next
              </Button>
            </div>
          </template>

          <template v-if="step == \'buildingSource\'">
            <div class="mb-8">
              <img :src="Logo" alt="Mercury.ai" class="mx-auto h-12 w-auto"/>
              <h2 class="mt-6 text-center text-2xl font-extrabold text-gray-800">
                Waiting for knowledge source to be built...
              </h2>
              <p class="mt-2 mb-0 text-center">
                Please wait for <span class="tabular-nums">{{ sourceCountdown }}</span> more seconds (or less)
              </p>
            </div>
            <div
              class="bg-white pt-6 pb-8 px-4 shadow sm:rounded-lg sm:px-10 overflow-hidden flex flex-col justify-end space-y-6"
              style="height: 30rem"
            >
              <WaitingAnimation/>
            </div>
          </template>
        </div>
      </div>
    '
  ).mount(element)

export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('project-creation-app')
      initProjectCreationApp(element)
