
  import Bot from 'models/bot.coffee'
  import ParameterTable from './parameter_table.vue'
  import { Button, DocumentationLink, PageHeader } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      bot: Bot

    methods:
      createContextParameter: ->
        @$router.push(name: 'newContextParameter')

    components:
      Button: Button
      DocumentationLink: DocumentationLink
      PageHeader: PageHeader
      ParameterTable: ParameterTable
      PlusIcon: PlusIcon
