
  import Verbalization from 'models/verbalization.coffee'
  import { Switch } from 'components/generic'

  export default
    props: 
      verbalization: Verbalization
      verbalizationConfig: Object
      active: Boolean

    emits: ['edit', 'activate', 'deactivate']

    methods:
      toggle: ->
        if @verbalization.isActive
          types = (Verbalization.IMPLICATIONS[@verbalization.type] || []).concat([@verbalization.type])
          @$emit('activate', types)
        else
          implyingTypes = []
          Object.keys(Verbalization.IMPLICATIONS).forEach (type) =>
            implyingTypes.push(type) if Verbalization.IMPLICATIONS[type].includes(@verbalization.type)
          types = implyingTypes.concat([@verbalization.type])
          @$emit('deactivate', types)

    components:
      Switch: Switch
