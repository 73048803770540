
  import BotbuilderApi from 'models/botbuilder_api.coffee'
  import UtteranceCollection from 'models/utterance_collection.coffee'
  import UtteranceEditor from './utterance_editor.vue'
  import { Alert, Button, Modal } from 'components/generic'
  import { SparklesIcon } from '@heroicons/vue/solid'

  export default
    props:
      utterances: UtteranceCollection
      noParseParams: Object
      withButton:
        type: Boolean
        default: true

    emits: ['add']

    data: ->
      suggestedUtterances: []
      loading: false
      errorMessage: null

    methods:
      start: ->
        @loading = true
        @errorMessage = null
        @suggestedUtterances = []
        @$refs.modal.open()
        (
          if @noParseParams?
            BotbuilderApi.noParseUtterances(@$root.activeBot, @noParseParams)
          else if @utterances?
            BotbuilderApi.autogenerateUtterances(
              @$root.currentBot
              utteranceCollection: @utterances
              language: @$root.currentLanguage
            )
          else
            Promise.reject()
        ).then (utterances) => @suggestedUtterances = utterances
        .catch (response) => @errorMessage = response.message
        .finally => @loading = false

      remove: (index) ->
        @suggestedUtterances.splice(index, 1)
      addSuggestions: ->
        @$emit('add', @suggestedUtterances)
        @$refs.modal.close()

    components:
      Alert: Alert
      Button: Button
      Modal: Modal
      SparklesIcon: SparklesIcon
      UtteranceEditor: UtteranceEditor
