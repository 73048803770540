import DialogTarget from 'models/dialog_target.coffee'
import PayloadSlot from 'models/payload_slot.coffee'

export default class Payload
  constructor: (@host, data) ->
    data ||= {}
    @target = new DialogTarget(@, data.target)
    @slots = (data.slots || []).map (slotData) => new PayloadSlot(@, slotData)

  clone: ->
    new Payload(@host, @export)

  addSlot: ->
    @slots.push(new PayloadSlot(@))

  Object.defineProperties @prototype,
    export:
      get: ->
        target: @target.export
        slots: @slots.map (slot) -> slot.export
    valid:
      get: -> @target.nodeKey?.length
    dialogModule:
      get: -> @host.dialogModule
    dialogNode:
      get: -> @host.dialogNode
    moduleKey:
      get: -> @dialogModule?.key
