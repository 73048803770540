
  import { ExternalLinkIcon } from '@heroicons/vue/outline'

  export default
    props:
      url: String
      label:
        type: String
        default: 'Documentation'

    components:
      ExternalLinkIcon: ExternalLinkIcon
