
  export default
    props:
      to: Object
      colspan:
        type: Number
        default: 1
      wrap:
        type: Boolean
        default: false
