
  import ContextParameter from 'models/context_parameter.coffee'
  import { Combobox } from 'components/generic'

  export default
    props:
      modelValue: String
      label: String
      placeholder: String
      keyStartsWith: String
      selectComponent:
        type: Boolean
        default: true

    emits: ['update:modelValue', 'remove']

    data: ->
      contextParameterKey: @modelValue
      originalParameterKey: @modelValue
      contextParameter: null
      selectingComponent: false
      component: null

    computed:
      components: ->
        @contextParameter?.components || []

    watch:
      modelValue: ->
        @contextParameterKey = @modelValue

    created: ->
      @init()

    methods:
      init: ->
        @contextParameter = @$root.contextParameterByKey(@contextParameterKey)
        @component = @$root.componentByKey(@contextParameterKey)
      update: ->
        @originalParameterKey = @contextParameterKey || ''
        @$emit('update:modelValue', @originalParameterKey)
      updateContextParameter: ->
        @contextParameterKey = @contextParameter?.key
        @component = null
        if @selectComponent && @contextParameter.components?
          @selectingComponent = true
          @$nextTick => @$refs.componentCombobox.open()
        else
          @update()
      updateComponent: ->
        return if !@component?.key?
        @selectingComponent = false
        @contextParameterKey += '#' + @component.key
        @update()
      cancel: ->
        @selectingComponent = false
        @contextParameterKey = @originalParameterKey
        @init()
      searchContextParameters: (query) ->
        @$root.currentBot.config.searchContextParameters(query)
          .then (cps) => @filteredByKey(cps)
      searchComponent: (query) ->
        pieces = query.split(' ')
        Promise.resolve(
          @components.filter (cmp) ->
            pieces.some (piece) ->
              cmp.key.toLowerCase().includes(piece) || cmp.label.toLowerCase().includes(piece)
        )
      contextParameterLabel: (cp) ->
        cp?.label || ''
      componentLabel: (component) ->
        component?.label || ''
      contextParameterAndComponentLabel: ->
        return '' if !@contextParameter?
        if @component?
          @component.fullLabel
        else
          @contextParameterLabel(@contextParameter)
      filteredByKey: (contextParameters) ->
        if @keyStartsWith?
          contextParameters.filter (cp) => cp.key.startsWith(@keyStartsWith)
        else
          contextParameters

    components:
      Combobox: Combobox
