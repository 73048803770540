
  import PaginationLink from './pagination_link.vue'
  import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid'

  export default
    props:
      apiMethod: Function
      params: Object
      pageSize:
        type: Number
        default: 50

    emits: ['loaded']

    data: ->
      items: []
      total: null
      error: null
      finished: false
      loading: false
      page: 0

    computed:
      computedParams: ->
        Object.assign({page: @page, pageSize: @pageSize}, @params)
      pageCount: ->
        Math.ceil(@total / @pageSize)
      pageNumbers: ->
        [0..(@pageCount - 1)]

    methods:
      load: ({page} = {page: null}) ->
        if page?
          @page = page
          @items = []
          @finished = false
        @loading = true
        @apiMethod(@computedParams)
          .then (data) =>
            @error = null
            @items = data.items || []
            @total = data.total
            @finished = true if @items.length < @pageSize
          .catch (response) =>
            @error = response.message
          .finally =>
            @loading = false
            @$emit('loaded')
      goToPrevious: ->
        return if @page == 0
        @page -= 1
        @load()
      goToNext: ->
        return if @finished
        @page += 1
        @load()
      goToPage: (pageNum) ->
        @page = pageNum
        @load()
      showPageLink: (page) ->
        return true if page <= 1
        return true if page >= @pageCount - 2
        return true if page >= @page - 1 && page <= @page + 1
        false
      showEllipsis: (page) ->
        return false if @showPageLink(page)
        return true if @showPageLink(page - 1)
        false
      remove: (index) ->
        @items.splice(index, 1)

    components:
      ChevronLeftIcon: ChevronLeftIcon
      ChevronRightIcon: ChevronRightIcon
      PaginationLink: PaginationLink
